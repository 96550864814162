import React from "react";
import { Link } from "react-router-dom";

export default class AccordionNavPage extends React.Component {
  constructor() {
    super();
    this.state = {
      open: {},
    };
  }

  renderLink([labelText, path]) {
    let content = path;

    const label = (
      <span>
        {labelText}
        {path.endsWith("pdf") ? (
          <img
            alt=""
            src="https://permiso-linked-assets.s3-us-west-1.amazonaws.com/baseline-picture_as_pdf-24px.svg"
            style={{ height: 20, marginLeft: 8, width: 20 }}
          />
        ) : null}
        {path.endsWith("video") ? (
          <img
            alt=""
            src="https://permiso-linked-assets.s3-us-west-1.amazonaws.com/baseline-play_circle_filled-24px.svg"
            style={{ height: 20, marginLeft: 8, width: 20 }}
          />
        ) : null}
      </span>
    );

    if (path.startsWith("http")) {
      content = (
        <a
          // eslint-disable-next-line
          href="javascript:void(0);"
          onClick={() =>
            (path.endsWith(".pdf") ||
              window.confirm(
                "You are about to be redirected outside of Pearl Hub"
              )) &&
            window.open(path, "_blank")
          }
        >
          {label}
        </a>
      );
    } else {
      content = <Link to={path}>{label}</Link>;
    }

    return (
      <div key={path} style={{ color: "#666", whiteSpace: "pre-wrap" }}>
        {content}
      </div>
    );
  }

  renderArrowByLabel(label) {
    return (
      <div
        style={{
          color: "#666",
          display: "inline-block",
          fontSize: "0.8em",
          fontWeight: 300,
          marginRight: "0.5em",
        }}
      >
        <i
          className={`fa fa-chevron-${this.state.open[label] ? "up" : "down"}`}
        />
      </div>
    );
  }

  toggleOpen(label) {
    this.setState({
      open: { ...this.state.open, [label]: !this.state.open[label] },
    });
  }

  render() {
    return (
      <div style={{ margin: "auto", maxWidth: 800, textAlign: "left" }}>
        <br />
        <br />

        {this.props.sections.map((section) => (
          <div key={section.label}>
            <h2
              onClick={this.toggleOpen.bind(this, section.label)}
              style={{ cursor: "pointer", fontWeight: 300 }}
            >
              {this.renderArrowByLabel(section.label)}
              {section.label}
            </h2>

            <div
              style={{
                // backgroundColor: '#f7f7f7',
                display: this.state.open[section.label] ? "block" : "none",
                paddingLeft: "3.2em",
                paddingRight: "1em",
              }}
            >
              <br />

              {section.sections
                ? section.sections.map((subSection, subIdx) => (
                    <div key={subSection.label}>
                      <h4
                        onClick={this.toggleOpen.bind(this, subSection.label)}
                        style={{ cursor: "pointer" }}
                      >
                        {this.renderArrowByLabel(subSection.label)}
                        {subSection.label}
                      </h4>

                      <div
                        style={{
                          display: this.state.open[subSection.label]
                            ? "block"
                            : "none",
                          paddingLeft: "2em",
                        }}
                      >
                        <br />
                        {subSection.links
                          ? subSection.links.map(this.renderLink.bind(this))
                          : null}
                      </div>

                      {subIdx < section.sections.length - 1 ? <hr /> : <br />}
                    </div>
                  ))
                : null}
              {section.links
                ? section.links.map(this.renderLink.bind(this))
                : null}
            </div>

            <hr />
          </div>
        ))}
      </div>
    );
  }
}
