import React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { getAssetURL } from "../../redux/settings";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

function SearchResultSection({
  section,
  title,
  page,
  highlightTexts,
  selectedCategories,
}) {
  let content = page.data[section];

  if (Array.isArray(content)) {
    if (content.length === 0) {
      if (!selectedCategories.find((sc) => sc.label === "Show All")) {
        content = (
          <Alert bsStyle="warning">
            There's no content available in this category. Please try other
            categories or countries...
          </Alert>
        );
      } else {
        return null;
      }
    } else {
      content = (
        <ul style={{ paddingLeft: "1em" }}>
          {content
            .map((row) => (row && row.value) || row)
            .filter((v) => v && v.length)
            .map((v, i) => {
              let html = v;
              highlightTexts.forEach((highlight) => {
                if (highlight.label.length > 0)
                  html = html.replace(
                    new RegExp(highlight.label, "gi"),
                    (match) =>
                      `<strong class="rounded-pill bg-primary text-light pr-2 pl-2">${match}</strong>`
                  );
              });
              return (
                <li
                  key={i}
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              );
            })}
        </ul>
      );
    }
  } else {
    if (!content || content.trim().length === 0) {
      if (!selectedCategories.find((sc) => sc.label === "Show All")) {
        content = (
          <Alert bsStyle="warning">
            There's no content available in this category. Please try other
            categories or countries...
          </Alert>
        );
      } else {
        return null;
      }
    } else {
      highlightTexts.forEach((highlight) => {
        if (highlight.label.length > 0)
          content = content.replace(
            new RegExp(highlight.label, "gi"),
            (match) =>
              `<strong class="rounded-pill bg-primary text-light pr-2 pl-2">${match}</strong>`
          );
      });
      content = (
        <p
          className="mb-3 text-left"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      );
    }
  }

  return (
    <Row>
      <Col>
        {title && (
          <React.Fragment>
            <h2 className="text-left">{title}</h2>
            <br />
          </React.Fragment>
        )}
        <div>{content}</div>
      </Col>
    </Row>
  );
}

function CountryDetailCard({ page, highlightTexts, selectedCategories }) {
  const imageStyle = {
    cursor: "pointer",
    maxWidth: "120px",
    padding: 0,
  };
  const highlightLabels = highlightTexts
    .map((h) => h.label)
    .filter((h) => h && h.length > 0);

  const flagUrl = page.url && `${getAssetURL()}/images/flags/${page.url}.png`;

  return (
    <Row
      style={{
        backgroundColor: "#f7f7f7",
        paddingLeft: "3.2em",
        paddingRight: "1em",
      }}
      className="mt-4 p-3"
    >
      <Col sm={3}>
        <img alt="flag" src={flagUrl} style={imageStyle} />
      </Col>
      <Col sm={9}>
        <Row className="mt-3">
          <h1>{page.data.country_name}</h1>
        </Row>
        {selectedCategories
          .flatMap((cat) =>
            cat.childKeys
              ? [
                  cat,
                  ...cat.childKeys.map((chk) => {
                    return { key: chk, label: "" };
                  }),
                ]
              : [cat]
          )
          .map((category) => {
            return (
              <SearchResultSection
                section={category.key}
                title={category.label}
                page={page}
                highlightTexts={highlightTexts}
                selectedCategories={selectedCategories}
              />
            );
          })}
        <Row>
          <NavLink
            to={`/country_page/${page.url}#${highlightLabels.join(",")}`}
          >
            Read more...
          </NavLink>
        </Row>
      </Col>
    </Row>
  );
}

export default withRouter(CountryDetailCard);
