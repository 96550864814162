import { dispatchResults } from "./app_actions";
import axiosApi from "../axiosApi";
import alertApiError from "../../helpers/alert_api_error";
import { ROOT_URL } from "../settings";
import * as actionTypes from "../action-types";

export function loadAnnouncements(domain_id, callback = () => {}) {
  const url = `${ROOT_URL}/announcements`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_ANNOUNCEMENTS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_ANNOUNCEMENTS,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_ANNOUNCEMENTS,
      });
      console.log(error);
      alertApiError(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_ANNOUNCEMENTS };
}
