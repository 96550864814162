import React from "react";

class CandidateAssessmentPage extends React.Component {
  state = {
    formData: {},
    page: 0,
  };

  renderInput(name, label, type) {
    const inputProps = {
      onChange: (e) =>
        this.setState({
          formData: { ...this.state.formData, [name]: e.target.value },
        }),
      style: { width: "100%" },
      type,
      value: this.state.formData[name] || "",
    };

    return (
      <div
        key={name}
        style={{
          display: "block",
          margin: "0.5em 10%",
          textAlign: "left",
          width: "80%",
        }}
      >
        <input {...inputProps} />
        <p>{label}</p>
      </div>
    );
  }

  renderForm(fields) {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          // textAlign: 'left',
        }}
      >
        {fields.map((f, i) =>
          f instanceof Array ? (
            this.renderInput(...f)
          ) : (
            <div key={i} style={{ width: "100%" }}>
              {f}
            </div>
          )
        )}
      </div>
    );
  }

  validate(keys) {
    return keys.every((k) => this.state.formData[k] !== undefined);
  }

  renderPage() {
    switch (this.state.page) {
      case 1:
        return (
          <div>
            <h3>Recruiter Information</h3>
            <br />
            {this.renderForm([
              ["recruiterFirstName", "First Name"],
              ["recruiterLastName", "Last Name"],
              ["recruiterEmail", "Email Address"],
            ])}
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn btn-primary"
                disabled={
                  !this.validate([
                    "recruiterFirstName",
                    "recruiterLastName",
                    "recruiterEmail",
                  ])
                }
                onClick={() => this.setState({ page: 2 })}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h3>Candidate Information</h3>
            <br />
            {this.renderForm([
              ["candidateFirstName", "First Name"],
              ["candidateLastName", "Last Name"],
              ["candidateEmail", "Email Address"],
            ])}
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ page: 1 })}
              >
                Back
              </button>
              <button
                className="btn btn-primary"
                disabled={
                  !this.validate([
                    "candidateFirstName",
                    "candidateLastName",
                    "candidateEmail",
                  ])
                }
                onClick={() => this.setState({ page: 3 })}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h3>Do you have the candidate's resume or LinkedIn profile?</h3>
            <br />
            <div style={{ fontSize: "1.5em" }}>
              <label
                onClick={() =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      hasCandidateResume: true,
                    },
                  })
                }
                style={{
                  marginRight: "1em",
                }}
              >
                <input
                  type="radio"
                  checked={!!this.state.formData.hasCandidateResume}
                  onChange={() => {}}
                />{" "}
                Yes
              </label>
              <label
                onClick={() =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      hasCandidateResume: false,
                    },
                  })
                }
              >
                <input
                  type="radio"
                  checked={!this.state.formData.hasCandidateResume}
                  onChange={() => {}}
                />{" "}
                No
              </label>
            </div>
            {this.state.formData.hasCandidateResume &&
              this.renderForm([
                ["candidateResumeLink", "Linked Profile"],
                <div>
                  - OR -<br />
                  <br />
                </div>,
                ["candidateResumeText", "Upload Resume", "file"],
              ])}
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ page: 2 })}
              >
                Back
              </button>
              <button
                className="btn btn-primary"
                disabled={
                  !(
                    !this.state.formData.hasCandidateResume ||
                    this.state.formData.candidateResumeText ||
                    this.state.formData.candidateResumeLink
                  )
                }
                onClick={() => this.setState({ page: 4 })}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h3>Proposed Position Information</h3>
            <br />
            {this.renderForm([["jobTitle", "Job Title"]])}
            <br />
            <h3>Do you have a job description for this position?</h3>
            <br />
            <div style={{ fontSize: "1.5em" }}>
              <label
                onClick={() =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      hasJobDescription: true,
                    },
                  })
                }
                style={{
                  marginRight: "1em",
                }}
              >
                <input
                  type="radio"
                  checked={!!this.state.formData.hasJobDescription}
                  onChange={() => {}}
                />{" "}
                Yes
              </label>
              <label
                onClick={() =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      hasJobDescription: false,
                    },
                  })
                }
              >
                <input
                  type="radio"
                  checked={!this.state.formData.hasJobDescription}
                  onChange={() => {}}
                />{" "}
                No
              </label>
            </div>
            {this.state.formData.hasJobDescription &&
              this.renderForm([
                [
                  "jobDescription",
                  "Please upload job description here",
                  "file",
                ],
              ])}
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ page: 3 })}
              >
                Back
              </button>
              <button
                className="btn btn-primary"
                disabled={
                  !(
                    this.validate(["jobTitle"]) &&
                    (!this.state.formData.hasJobDescription ||
                      this.validate(["jobDescription"]))
                  )
                }
                onClick={() => this.setState({ page: 5 })}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <h3>
              Thank you! A member of our legal staff will reach out to you
              within 1 business day to confirm the details of your request.
            </h3>
            <br />
            <br />
            <a className="btn btn-primary" href="/page/for-recruiters">
              Done
            </a>
          </div>
        );
      default:
        return (
          <div>
            <h3>Candidate Assessment Form</h3>
            <br />
            <p>
              Curious about whether or not you can extend an offer to a
              prospective new hire?
              <br />
              <br />
              This app will collect the information required for us to review
              the candidate’s background to ensure that no discrimination laws
              are violated and that potential immigration obstacles are
              identified.
            </p>
            <br />
            <div
              className="btn btn-primary"
              onClick={() => this.setState({ page: 1 })}
            >
              I Agree
            </div>
          </div>
        );
    }
  }

  render() {
    return (
      <div
        style={{
          border: "solid 1px black",
          borderRadius: 6,
          boxShadow: "5px 5px #ccc",
          margin: "auto",
          maxWidth: 500,
          padding: "2em",
        }}
      >
        <br />
        <br />
        {this.renderPage()}
      </div>
    );
  }
}

export default CandidateAssessmentPage;
