import FontAwesome from "react-fontawesome";
import React from "react";

export const bypassLogin = () => {
  var localStorageTest = localStorage.getItem("test");
  const url = new URL(window.location.href);
  const key = url.searchParams.get("key");
  if (key === "qwertyqwerty123") {
    localStorage.setItem("test", true);
    localStorageTest = true;
  }
  return localStorageTest;
};

export const getSubdomain = () => {
  let match1 = /([^.]+)?\.?staging.pearlhub.io/.exec(window.location.hostname);
  let match2 = /([^.]+)?\.?pearlhub.io/.exec(window.location.hostname);
  let match3 = /([^.]+)?\.?lvh.me/.exec(window.location.hostname);
  let match4 = /([^.]+)?\.?staging.permiso.net/.exec(window.location.hostname);
  let match5 = /([^.]+)?\.?permiso.uattopia.com/.exec(window.location.hostname);
  let match6 = /([^.]+)?\.?permiso.topia.com/.exec(window.location.hostname);
  if (match1) {
    if (match1[1]) {
      return match1[1];
    } else {
      return null;
    }
  } else if (match2) {
    if (match2[1]) {
      return match2[1];
    } else {
      return null;
    }
  } else if (match3) {
    if (match3[1]) {
      return match3[1];
    } else {
      return null;
    }
  } else if (match4) {
    if (match4[1]) {
      return match4[1];
    } else {
      return null;
    }
  } else if (match5) {
    if (match5[1]) {
      return match5[1];
    } else {
      return null;
    }
  } else if (match6) {
    if (match6[1]) {
      return match6[1];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getDomainBase = () => {
  let match1 = /([^.]+)?\.?staging.pearlhub.io/.exec(window.location.hostname);
  let match2 = /([^.]+)?\.?pearlhub.io/.exec(window.location.hostname);
  let match3 = /([^.]+)?\.?lvh.me/.exec(window.location.hostname);
  let match4 = /([^.]+)?\.?staging.permiso.net/.exec(window.location.hostname);
  let match5 = /([^.]+)?\.?permiso.uattopia.com/.exec(window.location.hostname);
  let match6 = /([^.]+)?\.?permiso.topia.com/.exec(window.location.hostname);
  if (match1) {
    return "staging.pearlhub.io";
  } else if (match2) {
    return "pearlhub.io";
  } else if (match3) {
    //  return 'staging.pearlhub.io';
    return "lvh.me:3000";
  } else if (match4) {
    return "staging.permiso.net";
  } else if (match5) {
    return "permiso.uattopia.com";
  } else if (match6) {
    return "permiso.topia.com";
  } else {
    return null;
  }
};

export const getGoogleAnalyticsId = () => {
  return "UA-142462001-1";
};

export const getCMSUrlBase = () => {
  return "https://cms.permiso.net/api/v1/permiso";
  // switch (getDomainBase()) {
  //   case 'staging.pearlhub.io':
  //     return('https://cms.staging.pearlhub.io/api/v1/permiso');
  //   case 'pearlhub.io':
  //     return('https://cms.pearlhub.io/api/v1/permiso');
  //   case 'lvh.me:3000':
  //     return('https://cms.pearlhub.io/api/v1/permiso');
  //   default:
  //     console.log('Domain base unexpected in CMS Url Base');
  // }
};

export const getServer = () => {
  let match1 = /([^.]+)?\.?staging.pearlhub.io/.exec(window.location.hostname);
  let match2 = /([^.]+)?\.?pearlhub.io/.exec(window.location.hostname);
  let match3 = /([^.]+)?\.?lvh.me/.exec(window.location.hostname);
  let match4 = /([^.]+)?\.?staging.permiso.net/.exec(window.location.hostname);
  let match5 = /([^.]+)?\.?permiso.uattopia.com/.exec(window.location.hostname);
  let match6 = /([^.]+)?\.?permiso.topia.com/.exec(window.location.hostname);
  if (match1) {
    if (match1[1]) {
      return `https://${match1[1]}.staging-api.permiso.net`;
    } else {
      return `https://staging-api.permiso.net`;
    }
  } else if (match2) {
    if (match2[1]) {
      return `https://${match2[1]}.api.permiso.net`;
    } else {
      return `https://api.permiso.net`;
    }
  } else if (match3) {
    if (match3[1]) {
      return `https://${match3[1]}.staging-api.permiso.net`;
    } else {
      return `https://staging-api.permiso.net`;
    }
  } else if (match4) {
    if (match4[1]) {
      return `https://${match4[1]}.staging-api.permiso.net`;
    } else {
      return `https://staging-api.permiso.net`;
    }
  } else if (match5) {
    if (match5[1]) {
      return `https://${match5[1]}.staging-api.permiso.uattopia.com`;
    } else {
      return `https://staging-api.permiso.uattopia.com`;
    }
  } else if (match6) {
    if (match6[1]) {
      return `https://${match6[1]}.api.permiso.topia.com`;
    } else {
      return `https://api.permiso.topia.com`;
    }
  } else {
    return "http://localhost:3001";
  }
};

export const getRootURL = () => {
  return `${getServer()}/api/v1`;
};

export const getGraphQLURL = () => {
  return `${getServer()}/graphql`;
  //return `http://localhost:4000/api`
};

export const getAssetURL = () =>
  "https://s3-us-west-1.amazonaws.com/permiso-assets";

export const SUBDOMAIN = getSubdomain();
export const DOMAIN_BASE = getDomainBase();
export const GA_ID = getGoogleAnalyticsId();
export const LOCAL = getDomainBase() === "lvh.me:3000";
export const BASE_URL = getServer();
export const ROOT_URL = getRootURL();
export const GRAPHQL_URL = getGraphQLURL();
export const CMS_URL_BASE = getCMSUrlBase();

export const stillUrls = ["/domain_admin", "/permiso_admin", "/reports"];

export const RULE_TYPES = [
  { id: "rename_existing_activity", name: "Rename Existing Activity" },
  { id: "hide_existing_activity", name: "Hide Existing Activities" },
  { id: "do_not_require_consult", name: "Do Not Require Consult" },
  { id: "require_consult", name: "Require Consult" },
  { id: "business_visa_activity", name: "Business Visa Activity" },
  { id: "not_business_visa_activity", name: "Not a Business Visa Activity" },
];

// enum semantic_type: [:output_rule, :override_rule]
// enum overall_assessment: [:any_result, :consult_required, :no_consult_required]
// enum override_outcome: [:no_override, :require_consult, :do_not_require_consult]

export const OUTCOME_RULE_TYPES = [
  { id: "output_rule", name: "Output Rule" },
  { id: "override_rule", name: "Override Rule" },
];

export const ASSESSMENT_RESULT = [
  { id: "any_result", name: "Any Result" },
  { id: "consult_required", name: "Consult Required" },
  { id: "consult_not_required", name: "Consult Not Required" },
];

export const OVERRIDE_OUTCOME = [
  { id: "no_override", name: "No Override" },
  { id: "require_consult", name: "Require Consult" },
  { id: "no_consult_required", name: "No Consult Required" },
];

export const RESULT_ICONS = {
  not_possible: (
    <FontAwesome style={{ color: "#007bff" }} name="minus-circle" />
  ),
  free_travel: <FontAwesome style={{ color: "#007bff" }} name="thumbs-up" />,
  info: <FontAwesome style={{ color: "#007bff" }} name="info-circle" />,
  visa: <FontAwesome style={{ color: "#007bff" }} name="info-circle" />,
  permit: (
    <FontAwesome style={{ color: "#007bff" }} name="exclamation-circle" />
  ),
  permit_waiver: (
    <FontAwesome style={{ color: "#007bff" }} name="info-circle" />
  ),
  full_waiver: <FontAwesome style={{ color: "#007bff" }} name="info-circle" />,
  waiver: <FontAwesome style={{ color: "#007bff" }} name="info-circle" />,
  warning: (
    <FontAwesome style={{ color: "#007bff" }} name="exclamation-circle" />
  ),
  consult_not_required: (
    <FontAwesome style={{ color: "#007bff" }} name="check-circle" />
  ),
  consult_required: (
    <FontAwesome style={{ color: "#007bff" }} name="hand-stop-o" />
  ),
};

export const ROLES = [
  { value: "user", label: "User" },
  { value: "admin", label: "Admin" },
  { value: "dev", label: "Developer" },
];

export const RELATIONSHIPS = [
  {
    id: "visa_waiver",
    name: "Visa Waiver",
    explanation: "Waiver to permit at general duration",
  },
  {
    id: "partial_visa_waiver",
    name: "Partial Waiver",
    explanation: "Waiver to visa shorter than general duration",
  },
  {
    id: "permit_waiver",
    name: "Permit Waiver",
    explanation: "Waiver to permit shorter than general duration",
  },
  {
    id: "full_waiver",
    name: "Full Waiver",
    explanation: "Waiver to permit and visa shorter than general duration",
  },
  {
    id: "visa_required",
    name: "Visa Required",
    explanation: "Visa required in default waiver country",
  },
  {
    id: "scrutiny",
    name: "Scrutiny",
    explanation: "Warning to expect scrutiny. No impact on outcome.",
  },
  {
    id: "banned",
    name: "Banned",
    explanation: "Travel not possible. Banned country.",
  },
  {
    id: "gap_required",
    name: "Gap Required",
    explanation: "Gap required between visits.",
  },
  {
    id: "free_movement",
    name: "Free Movement",
    explanation: "Freedom of movement between source and destination.",
  },
  {
    id: "case_by_case",
    name: "Case-by-case",
    explanation: "Visitors are evaluated on a case-by-case basis.",
  },
  {
    id: "maximum_stay",
    name: "Maximum Stay",
    explanation: "There is a hard maximum stay limitation.",
  },
  {
    id: "cumulative_stay",
    name: "Cumulative Stay",
    explanation: "There is a cumulative stay limit with an outcome.",
  },
  {
    id: "activity_waiver",
    name: "Activity Waiver",
    explanation: "Visitors can perform specific activity under a waiver.",
  },
  {
    id: "permanent_resident_waiver",
    name: "Permanent Resident Waiver",
    explanation: "Visitors can travel without a visa.",
  },
];

export const STATUSES = [
  {
    id: "permit",
    name: "Permit",
  },
];

export const SOURCE_GROUP_OUTCOMES = [
  {
    id: "permit",
    name: "Permit",
  },
  {
    id: "consult_required",
    name: "Consult",
  },
];
