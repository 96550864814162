import { addFlashMessage, addErrorMessage } from "../redux/actions/ui_actions";

const alertApiError = (error) => {
  const response = error.response;
  console.log(error);
  if (response) {
    if (response.status === 422) {
      addFlashMessage(
        response.config.url,
        "notice",
        response.data.errors.join(", ")
      );
    } else {
      addErrorMessage(
        response.config.url,
        "Something went wrong. We've been notified and are working to fix the problem right away."
      );
    }
  }
};

export default alertApiError;
