import React from "react";

import AccordionNavPage from "../shared/AccordionNavPage";

export const sections = [
  {
    label: "General Information",
    links: [
      [
        "Tips for International Business Travel",
        "/page/tips-for-international-travel",
      ],
      [
        "General Requirements for Business Travelers",
        "/page/general-reqs-for-biz-travelers",
      ],
      [
        "Guidelines For Frequent Travelers to the U.S.",
        "/page/frequent-business-travelers-to-us",
      ],
      ["Passport Information", "/page/passport-info"],
    ],
  },
  {
    label: "U.S. Global Entry and TSA Pre-Check",
    links: [
      ["Overview", "/page/trusted-traveler-programs"],
      ["Mobile Passport App", "https://mobilepassport.us/"],
    ],
  },
  {
    label: "Helpful Links",
    links: [
      ["Currency Converter", "https://www.xe.com/currencyconverter/"],
      ["World Clock", "https://www.timeanddate.com/worldclock/full.html"],
      [
        "U.S Department of State Travel Advisories",
        "https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories.html/",
      ],
      [
        "Schengen Calculator",
        "https://ec.europa.eu/assets/home/visa-calculator/calculator.htm?lang=en",
      ],
      [
        "U.S. CDC Health Resources for Travelers",
        "https://wwwnc.cdc.gov/travel/destinations/list",
      ],
      ["U.S. Embassies Worldwide", "https://www.usembassy.gov/"],
    ],
  },
  {
    label: "Other Trusted Traveler Programs",
    links: [
      [
        "APEC Business Travel Card",
        "https://www.apec.org/Groups/Committee-on-Trade-and-Investment/Business-Mobility-Group/ABTC",
      ],
      [
        "Australia: SmartGates",
        "https://www.abf.gov.au/entering-and-leaving-australia/smartgates/departures",
      ],
      [
        "Canada: CANPASS",
        "https://www.cbsa-asfc.gc.ca/prog/canpass/menu-eng.html",
      ],
      [
        "Germany: EasyPASS",
        "https://www.easypass.de/EasyPass/EN/EasyPASS-RTP/rtp_node.html",
      ],
      [
        "Hong Kong: E-Channel",
        "https://www.immd.gov.hk/eng/services/echannel_residents.html#apc",
      ],
      [
        "Japan: Automated Gates",
        "http://www.immi-moj.go.jp/ttp2/en/pdf/info.pdf",
      ],
      [
        "Mexico: Reliable Traveler",
        "https://www.gob.mx/inm/acciones-y-programas/viajero-confiable",
      ],
      ["Netherlands: Privium", "https://www.schiphol.nl/en/privium/"],
      [
        "South Korea: Smart Entry Service",
        "https://www.ses.go.kr/ses/SesInfoR_en.ses",
      ],
      ["UK: Registered Traveler", "https://www.gov.uk/registered-traveller"],
      ["NEXUS", "https://www.cbsa-asfc.gc.ca/prog/nexus/menu-eng.html"],
      ["SENTRI", "https://www.cbp.gov/travel/trusted-traveler-programs/sentri"],
    ],
  },
];

export default () => <AccordionNavPage sections={sections} />;
