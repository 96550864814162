import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { clearErrorMessages } from "../../redux/actions/ui_actions";
import { withRouter } from "react-router-dom";

class ErrorModal extends Component {
  goHome = (i) => {
    this.props.clearErrorMessages();
    this.props.history.push("/home");
  };

  onClose = (i) => {
    this.props.clearErrorMessages();
  };

  render() {
    const { messages } = this.props;
    if (messages && messages.length > 0) {
      return (
        <div className="static">
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>

            <Modal.Body className="text-left">
              {messages.map((message, index) => {
                return <div key={index}>{message.content}</div>;
              })}
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.onClose}>Close</Button>
              <Button onClick={this.goHome} bsStyle="primary">
                Home
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.ui.error_messages,
  };
};

const connectedComponent = connect(mapStateToProps, {
  clearErrorMessages,
})(ErrorModal);

export default withRouter(connectedComponent);
