import React, { Component } from "react";

export default class Status extends Component {
  render() {
    const { periodString, viewing } = this.props;
    return (
      <div className="d-flex flex-column justify-content-around align-content-start my-4 ml-4">
        <h3 className="text-left">Period: {periodString}</h3>
        <div>
          <h4 className="text-left">Now displaying: </h4>
          {viewing.map((level, i) => {
            return (
              <div className="text-left" key={i}>
                {i > 0 && <span> &#8594; </span>}
                {level.name} ({level.value})
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
