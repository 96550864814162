import docvaultImg from "../../../images/icons/docvault.png";
import globeImg from "../../../images/icons/globe.png";
import newsImg from "../../../images/icons/news.png";
import peopleImg from "../../../images/icons/people.png";
import phoneImg from "../../../images/icons/phone.png";
import pointersImg from "../../../images/icons/pointers.png";
import reportsImg from "../../../images/icons/reports.png";
import websiteImg from "../../../images/icons/website.png";

const assetsBaseUrl =
  "https://s3-us-west-1.amazonaws.com/permiso-assets/images/icons/";

const navigationConfig = [
  {
    id: "permiso",
    title: "Permiso Travel Tool",
    link: "/assessments/home",
    icon: pointersImg,
    description:
      "Assess immigration requirements for business travel using our online tool, Permiso",
    children: [
      {
        id: "new-assessment",
        title: "New Assessment",
        link: "/assessments/new",
      },
      {
        id: "calendar",
        title: "Calendar",
        link: "/calendar",
      },
      {
        id: "profile",
        title: "Profile",
        link: "/profile",
      },
      {
        id: "assessment-view",
        title: "View Assessment",
        link: "/assessments/show/*",
      },
    ],
  },
  {
    id: "countries",
    title: "Immigration by Country",
    link: "/wiki/country_pages",
    icon: globeImg,
  },
  {
    id: "immigration_cases",
    title: "Immigration Case System",
    link: "https://pearllawgroup.casemgmtsys.com/home/login",
    icon: phoneImg,
    description:
      "Easily upload immigration documents, and track case from anywhere. Proactively manage global cases",
  },
  {
    id: "traveler_links",
    title: "Traveler Links",
    icon: websiteImg,
    link: "/business-travel-resources",
  },
  {
    id: "news",
    title: "Immigration News",
    link: "/immigration-news",
    icon: newsImg,
    description: "The latest Immigration News headlines from around the world",
  },
  {
    id: "team_resources",
    title: "Team Resources",
    link: "/team_resources",
    icon: peopleImg,
    children: [
      {
        id: "rec",
        title: "Recruiter Tools",
        link: "/recruiter-tools",
        icon: `${assetsBaseUrl}icon-recruiter.png`,
        description:
          "Comply with GDPR and other rules while evaluating international candidates",
        children: [
          {
            id: "candidate_assessment",
            title: "Candidate Assessment",
            link: "/candidate-assessment",
          },
          {
            id: "recruiter_resources",
            title: "Recruiter Resources",
            link: "/page/for-recruiters",
          },
        ],
      },
      {
        id: "resources",
        title: "Team Info",
        link: "/page/global-mobility",
        icon: `${assetsBaseUrl}icon-information.png`,
        description:
          "Get in touch with your Global Mobility, HR or Immigration Team",
      },
    ],
  },
  {
    id: "doc_vault",
    title: "Document Vault",
    link: "https://immigrationlaw.sharepoint.com/sites/modernexternal",
    icon: docvaultImg,
    description:
      "Centrally store documents to be ready for a potential audit, from Public Access Files (PAFs) to PERM recruitment documents",
  },
  {
    id: "reports",
    title: "Reports",
    link: "/permiso-reports",
    icon: reportsImg,
    children: [
      {
        id: "report_destination",
        title: "Destinations",
        link: "/reports/destinations",
        description: "Summary of Assessments and Outcomes",
        navType: "list",
      },
      {
        id: "report_location",
        title: "Locations",
        link: "/reports/location",
        description: "Current Travelers by Country",
        navType: "list",
      },
      {
        id: "report_tax",
        title: "Tax Report",
        link: "/reports/tax",
        description: "",
        navType: "list",
      },
      {
        id: "report_custom",
        title: "Custom Report",
        link: "/reports/custom",
        navType: "list",
      },
    ],
  },
];

const filterTilesRec = (tiles, filterFun) => {
  return tiles.filter(filterFun).map((item) => {
    if (item.children) {
      item.children = filterTilesRec(item.children, filterFun);
    }
    return item;
  });
};

export default function getRootNavigationTiles(filter = (element) => true) {
  return filterTilesRec(navigationConfig, filter);
}
