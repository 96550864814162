import { initialState } from "../initial_state";
import update from "immutability-helper";
import * as actionTypes from "../action-types";

export default (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.LOAD_USER:
      newState = update(state, {
        $set: action.payload,
      });
      return newState;
    case actionTypes.LOAD_AGENT_USER:
      newState = update(state, {
        agent: { $set: action.payload },
      });
      return newState;
    case actionTypes.LOAD_ASSESSMENTS:
      newState = update(state, {
        assessments: { $set: action.payload },
      });
      return newState;
    case actionTypes.LOAD_USER_APPLICATIONS:
      newState = update(state, {
        applications: { $set: action.payload },
      });
      return newState;
    case actionTypes.LOAD_TRIPS:
      newState = update(state, {
        trips: { $set: action.payload },
      });
      return newState;
    case actionTypes.LOAD_AGENTS:
      newState = update(state, {
        agents: { $set: action.payload },
        agent_ids: {
          $set: action.payload.map((agent, i) => {
            return "" + agent.id;
          }),
        },
      });
      return newState;
    case actionTypes.UPDATE_AGENTS:
      newState = update(state, {
        agents: { $set: action.payload },
      });
      return newState;
    case actionTypes.UPDATE_PROFILE:
      newState = update(state, {
        user: { $set: action.payload },
      });
      return newState;
    case actionTypes.LOAD_DOMAINS:
      newState = update(state, {
        domains: { $set: action.payload },
      });
      return newState;
    case actionTypes.SWITCH_DOMAIN:
      newState = update(state, {
        user: { $set: action.payload },
      });
      return newState;
    default:
      return state;
  }
};
