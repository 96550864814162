import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer">
      <span>&copy; {new Date().getFullYear()} Pearl Global Tech - </span>
      <a href="/privacy">Privacy</a>
      {" | "}
      <a href="/terms">Terms of Use</a>
    </div>
  );
};

export default withRouter(Footer);
