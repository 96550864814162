import React from "react";

const ErrorPage = (props) => {
  return (
    <div
      className="d-flex h-100 flex-column justify-content-around"
      style={{
        color: "#063D5D",
        marginTop: 50,
        textAlign: "center",
      }}
    >
      <img
        src={"https://www.topia.com/wp-content/uploads/2019/12/topia-logo.svg"}
        alt=""
        style={{ height: 50, margin: "auto" }}
      />
      <h3>Oops.</h3>
      <h3>We've hit a snag.</h3>
      <br />
      <div>
        You can go back to <a href={window.location.origin}>Permiso</a> and try
        again, or{" "}
        <a
          href="https://pearlglobaltech.freshdesk.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          submit a support ticket
        </a>{" "}
        so that we can start working on this right away.
      </div>
    </div>
  );
};

export default ErrorPage;
