export const LOAD_USER = "LOAD_USER";
export const ADD_PASSPORT = "ADD_PASSPORT";
export const UPDATE_PASSPORT = "UPDATE_PASSPORT";
export const DELETE_PASSPORT = "DELETE_PASSPORT";
export const LOAD_AGENT_USER = "LOAD_AGENT_USER";
export const LOAD_ASSESSMENTS = "LOAD_ASSESSMENTS";
export const LOAD_USER_APPLICATIONS = "LOAD_USER_APPLICATIONS";
export const LOAD_AGENTS = "LOAD_AGENTS";
export const UPDATE_AGENTS = "UPDATE_AGENTS";
export const DELETE_ASSESSMENT = "DELETE_ASSESSMENT";
export const DELETE_USER_APPLICATION = "DELETE_USER_APPLICATION";
export const UPDATE_USER_APPLICATION = "UPDATED_USER_APPLICATION";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOAD_DOMAINS = "LOAD_DOMAINS";
export const SWITCH_DOMAIN = "SWITCH_DOMAIN";
export const LOAD_DASHBOARD_TILES_VISIBILITY =
  "LOAD_DASHBOARD_TILES_VISIBILITY";

export const LOAD_APPLICATIONS = "LOAD_APPLICATIONS";
export const CREATE_APPLICATION = "CREATE_APPLICATION";
export const CHOOSE_ASSESSMENT = "CHOOSE_ASSESSMENT";

export const LOAD_DOMAIN_ACTIVITIES = "LOAD_DOMAIN_ACTIVITIES";
export const CLEAR_DOMAIN_ACTIVITIES = "CLEAR_DOMAIN_ACTIVITIES";

export const DOMAIN_LOAD_ANNOUNCEMENTS = "DOMAIN_LOAD_ANNOUNCEMENTS";

export const LOAD_PAGES = "LOAD_PAGES";

export const LOAD_SCREENING_QUESTIONS = "LOAD_SCREENING_QUESTIONS";

export const LOAD_UNAVAILABLE_MESSAGES = "LOAD_UNAVAILABLE_MESSAGES";

export const DOMAIN_ADMIN_LOAD_ANNOUNCEMENTS =
  "DOMAIN_ADMIN_LOAD_ANNOUNCEMENTS";
export const DOMAIN_ADMIN_UPDATE_ANNOUNCEMENT =
  "DOMAIN_ADMIN_UPDATE_ANNOUNCEMENT";
export const DOMAIN_ADMIN_DELETE_ANNOUNCEMENT =
  "DOMAIN_ADMIN_DELETE_ANNOUNCEMENT";

export const LOAD_COUNTRY_PAGE_ADDITIONS = "LOAD_COUNTRY_PAGE_ADDITIONS";
export const CREATE_COUNTRY_PAGE_ADDITION = "CREATE_COUNTRY_PAGE_ADDITION";
export const UPDATE_COUNTRY_PAGE_ADDITION = "UPDATE_COUNTRY_PAGE_ADDITION";
export const DELETE_COUNTRY_PAGE_ADDITION = "DELETE_COUNTRY_PAGE_ADDITION";

export const DOMAIN_ADMIN_LOAD_CUSTOMIZATION =
  "DOMAIN_ADMIN_LOAD_CUSTOMIZATION";
export const DOMAIN_ADMIN_UPDATING_ACTIVITY = "DOMAIN_ADMIN_UPDATING_ACTIVITY";
export const DOMAIN_ADMIN_DELETE_ACTIVITY = "DOMAIN_ADMIN_DELETE_ACTIVITY";

export const CREATE_DOMAIN_ADMIN_PAGE = "CREATE_DOMAIN_ADMIN_PAGE";
export const UPDATE_DOMAIN_ADMIN_PAGE = "UPDATE_DOMAIN_ADMIN_PAGE";
export const DELETE_DOMAIN_ADMIN_PAGE = "DELETE_DOMAIN_ADMIN_PAGE";

export const CREATE_DOMAIN_ADMIN_SCREENING_QUESTION =
  "CREATE_DOMAIN_ADMIN_SCREENING_QUESTION";
export const UPDATE_DOMAIN_ADMIN_SCREENING_QUESTION =
  "UPDATE_DOMAIN_ADMIN_SCREENING_QUESTION";
export const DELETE_DOMAIN_ADMIN_SCREENING_QUESTION =
  "DELETE_DOMAIN_ADMIN_SCREENING_QUESTION";

export const LOAD_SUBSTITUTIONS = "LOAD_SUBSTITUTIONS";
export const CREATE_SUBSTITUTION = "CREATE_SUBSTITUTION";
export const UPDATE_SUBSTITUTION = "UPDATE_SUBSTITUTION";
export const DELETE_SUBSTITUTION = "DELETE_SUBSTITUTION";

export const CREATE_UNAVAILABLE_MESSAGE = "CREATE_UNAVAILABLE_MESSAGE";
export const UPDATE_UNAVAILABLE_MESSAGE = "UPDATE_UNAVAILABLE_MESSAGE";
export const DELETE_UNAVAILABLE_MESSAGE = "DELETE_UNAVAILABLE_MESSAGE";

export const PERMISO_ADMIN_LOAD_ACTIVITIES = "PERMISO_ADMIN_LOAD_ACTIVITIES";
export const PERMISO_ADMIN_CLEAR_ACTIVITIES = "PERMISO_ADMIN_CLEAR_ACTIVITIES";
export const PERMISO_ADMIN_UPDATING_ACTIVITY =
  "PERMISO_ADMIN_UPDATING_ACTIVITY";

export const PERMISO_ADMIN_LOAD_ANNOUNCEMENTS =
  "PERMISO_ADMIN_LOAD_ANNOUNCEMENTS";
export const PERMISO_ADMIN_UPDATE_ANNOUNCEMENT =
  "PERMISO_ADMIN_UPDATE_ANNOUNCEMENT";
export const PERMISO_ADMIN_DELETE_ANNOUNCEMENT =
  "PERMISO_ADMIN_DELETE_ANNOUNCEMENT";

export const PERMISO_ADMIN_LOAD_COUNTRY = "PERMISO_ADMIN_LOAD_COUNTRY";
export const PERMISO_ADMIN_CREATE_COUNTRY = "PERMISO_ADMIN_CREATE_COUNTRY";
export const PERMISO_ADMIN_UPDATE_COUNTRY = "PERMISO_ADMIN_UPDATE_COUNTRY";
export const PERMISO_ADMIN_UPDATE_COUNTRY_ACTIVITY =
  "PERMISO_ADMIN_UPDATE_COUNTRY_ACTIVITY";
export const PERMISO_ADMIN_DELETE_COUNTRY_ACTIVITY =
  "PERMISO_ADMIN_DELETE_COUNTRY_ACTIVITY";
export const PERMISO_ADMIN_UPDATE_COUNTRY_SOURCE_GROUP =
  "PERMISO_ADMIN_UPDATE_COUNTRY_SOURCE_GROUP";
export const PERMISO_ADMIN_DELETE_COUNTRY_SOURCE_GROUP =
  "PERMISO_ADMIN_DELETE_COUNTRY_SOURCE_GROUP";
export const PERMISO_ADMIN_UPDATE_COUNTRY_AGE_RULE =
  "PERMISO_ADMIN_UPDATE_COUNTRY_AGE_RULE";
export const PERMISO_ADMIN_DELETE_COUNTRY_AGE_RULE =
  "PERMISO_ADMIN_DELETE_COUNTRY_AGE_RULE";
export const PERMISO_ADMIN_UPDATE_COUNTRY_DURATION_RULE =
  "PERMISO_ADMIN_UPDATE_COUNTRY_DURATION_RULE";
export const PERMISO_ADMIN_DELETE_COUNTRY_DURATION_RULE =
  "PERMISO_ADMIN_DELETE_COUNTRY_DURATION_RULE";
export const PERMISO_ADMIN_UPDATE_COUNTRY_PASSPORT_RULE =
  "PERMISO_ADMIN_UPDATE_COUNTRY_PASSPORT_RULE";
export const PERMISO_ADMIN_DELETE_COUNTRY_PASSPORT_RULE =
  "PERMISO_ADMIN_DELETE_COUNTRY_PASSPORT_RULE";
export const PERMISO_ADMIN_UPDATE_COUNTRY_AIRPORT =
  "PERMISO_ADMIN_UPDATE_COUNTRY_AIRPORT";
export const PERMISO_ADMIN_DELETE_COUNTRY_AIRPORT =
  "PERMISO_ADMIN_DELETE_COUNTRY_AIRPORT";

export const LOAD_PERMISO_ADMIN_NEWS = "LOAD_PERMISO_ADMIN_NEWS";
export const CREATE_PERMISO_ADMIN_NEWS = "CREATE_PERMISO_ADMIN_NEWS";
export const UPDATE_PERMISO_ADMIN_NEWS = "UPDATE_PERMISO_ADMIN_NEWS";
export const DELETE_PERMISO_ADMIN_NEWS = "DELETE_PERMISO_ADMIN_NEWS";

export const LOAD_ADMIN_OUTCOME_RULES = "LOAD_ADMIN_OUTCOME_RULES";
export const CREATE_ADMIN_OUTCOME_RULE = "CREATE_ADMIN_OUTCOME_RULE";
export const UPDATE_ADMIN_OUTCOME_RULE = "UPDATE_ADMIN_OUTCOME_RULE";
export const DELETE_ADMIN_OUTCOME_RULE = "DELETE_ADMIN_OUTCOME_RULE";

export const LOAD_PERMISO_ADMIN_PAGES = "LOAD_PERMISO_ADMIN_PAGES";
export const CREATE_PERMISO_ADMIN_PAGE = "CREATE_PERMISO_ADMIN_PAGE";
export const UPDATE_PERMISO_ADMIN_PAGE = "UPDATE_PERMISO_ADMIN_PAGE";
export const DELETE_PERMISO_ADMIN_PAGE = "DELETE_PERMISO_ADMIN_PAGE";

export const PERMISO_ADMIN_LOAD_SPECIAL_GROUPS =
  "PERMISO_ADMIN_LOAD_SPECIAL_GROUPS";
export const PERMISO_ADMIN_CREATE_SPECIAL_GROUP =
  "PERMISO_ADMIN_CREATE_SPECIAL_GROUP";
export const PERMISO_ADMIN_UPDATE_SPECIAL_GROUP =
  "PERMISO_ADMIN_UPDATE_SPECIAL_GROUP";
export const PERMISO_ADMIN_DELETE_SPECIAL_GROUP =
  "PERMISO_ADMIN_DELETE_SPECIAL_GROUP";

export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES";
export const CLEAR_ACTIVITIES = "CLEAR_ACTIVITIES";

export const LOAD_ANNOUNCEMENTS = "LOAD_ANNOUNCEMENTS";

export const LOAD_REPORT_DOWNLOAD = "LOAD_REPORT_DOWNLOAD";

export const LOAD_CONCUR_CONNECTED = "LOAD_CONCUR_CONNECTED";
export const LOAD_PENDING_CONCUR_TRIPS = "LOAD_PENDING_CONCUR_TRIPS";
export const LOAD_CONCUR_TRIP_DETAILS = "LOAD_CONCUR_TRIP_DETAILS";
export const IGNORE_CONCUR_TRIP = "IGNORE_CONCUR_TRIP";
export const IMPORT_CONCUR_TRIP = "IMPORT_CONCUR_TRIP";
export const CHECK_TRIP_CONFLICTS = "CHECK_TRIP_CONFLICTS";

export const LOAD_COUNTRIES = "LOAD_COUNTRIES";

export const LOAD_OUTCOMES_RULES = "LOAD_OUTCOMES_RULES";
export const CREATE_OUTCOMES_RULE = "CREATE_OUTCOMES_RULE";
export const UPDATE_OUTCOMES_RULE = "UPDATE_OUTCOMES_RULE";
export const DELETE_OUTCOMES_RULE = "DELETE_OUTCOMES_RULE";

export const LOAD_DOMAIN = "LOAD_DOMAIN";
export const LOAD_DOMAIN_AGENTS = "LOAD_DOMAIN_AGENTS";
export const LOAD_DOMAIN_APPLICATIONS = "LOAD_DOMAIN_APPLICATIONS";

export const LOAD_DOMAIN_ADMIN_SETTINGS = "LOAD_DOMAIN_ADMIN_SETTINGS";
export const UPDATE_DOMAIN_ADMIN_SETTINGS = "UPDATE_DOMAIN_ADMIN_SETTINGS";

export const LOAD_REPORT_DATA = "LOAD_REPORT_DATA";
export const LOADING = "LOADING";

export const UPDATE_TRIP = "UPDATE_TRIP";
export const LOAD_TRIPS = "LOAD_TRIPS";
export const CREATE_TRIP = "CREATE_TRIP";
export const GENERATE_TRIP = "GENERATE_TRIP";
export const DELETE_TRIP = "DELETE_TRIP";

export const TOGGLE_MENU = "TOGGLE_MENU";
export const COMPLETE = "COMPLETE";
export const INCOMPLETE = "INCOMPLETE";
export const SET_BASENAME = "SET_BASENAME";
export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE";
export const ADD_ERROR_MESSAGE = "ADD_ERROR_MESSAGE";
export const CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES";

export const DELETE_UPLOAD = "DELETE_UPLOAD";
export const LOAD_UPLOADS = "LOAD_UPLOADS";

export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
