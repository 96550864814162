import React from "react";

const ImmigrationNewsPage = () => {
  return (
    <iframe
      src={`https://pearl-widgets.s3-us-west-1.amazonaws.com/index.html?date=${Date.now()}`}
      style={{
        border: "none",
        height: "calc(100vh - 136px)",
        width: "100%",
      }}
    ></iframe>
  );
};

export default ImmigrationNewsPage;
