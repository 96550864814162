import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { setLoading, setComplete } from "../../redux/actions/ui_actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Sunburst from "./Sunburst";

class SunburstChart extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.shouldRender;
  }

  render() {
    if (this.props.data.loading) {
      return <div>Loading...</div>;
    } else {
      if (!this.props.chartData) {
        this.props.onCompleted(this.props.data.countries);
      }
      return <Sunburst {...this.props} data={this.props.chartData} />;
    }
  }
}

const ReportQuery = gql`
  query ReportQuery($start_date: String, $end_date: String) {
    countries {
      name
      assessments(start_date: $start_date, end_date: $end_date) {
        result
        result_string
        status
        status_string
      }
    }
  }
`;

export default compose(
  graphql(ReportQuery, {
    options(ownProps) {
      return {
        variables: {
          domain: ownProps.domainId,
          start_date: ownProps.startDate.format("YYYY-MM-DD") || "",
          end_date: ownProps.endDate.format("YYYY-MM-DD") || "",
        },
        errorPolicy: "all",
      };
    },
  })
)(connect(null, { setLoading, setComplete })(SunburstChart));
