import store from "../store";
import axiosApi from "../axiosApi";
import alertApiError from "../../helpers/alert_api_error";
import { loadUserApplications } from "./user_actions";
import { ROOT_URL } from "../settings";
import * as actionTypes from "../action-types";

export function createApplication(
  values,
  callback = () => {
    return null;
  }
) {
  const user_id = values.user_id;
  const url = `${ROOT_URL}/users/${user_id}/applications`;

  try {
    values.arrive = values.arrive.format("YYYY-MM-DD");
  } catch (err) {}
  try {
    values.depart = values.depart.format("YYYY-MM-DD");
  } catch (err) {}

  try {
    values.destination_region = values.destination_region.label;
  } catch (e) {}
  try {
    values.destination_city = values.destination_city.label;
  } catch (e) {}

  axiosApi
    .post(url, values)
    .then((result) => {
      dispatchResults(loadUserApplications(user_id));
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.CREATE_APPLICATION,
      });
      callback(result.data.application_id);
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.CREATE_APPLICATION,
      });
      console.log(error);
      alertApiError(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.CREATE_APPLICATION };
}

export function chooseAssessment(
  user_id,
  app_id,
  assessment_id,
  callback = () => {
    return null;
  }
) {
  const url = `${ROOT_URL}/users/${user_id}/applications/${app_id}/chosen_assessment`;
  const values = { chosen_assessment_id: assessment_id };
  axiosApi
    .post(url, values)
    .then((result) => {
      dispatchResults(loadUserApplications(user_id));
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.CHOOSE_ASSESSMENT,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.CHOOSE_ASSESSMENT,
      });
      console.log(error);
      alertApiError(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.CHOOSE_ASSESSMENT };
}

export function dispatchResults(action) {
  store.dispatch(action);
}

export function currentUser() {
  return store.getState().user;
}
