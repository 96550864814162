import React, { Component } from "react";
import GA from "./GoogleAnalytics";
import "react-dates/initialize";
import "../css/App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-toggle/css/bootstrap-toggle.min.css";
import "font-awesome/css/font-awesome.css";
import TopNav from "./shared/TopNav";
import PermisoSideNav from "./shared/PermisoSideNav";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import Home from "./home";
import Callback from "./callback/Callback";
import Page from "./wiki/Page";
import Auth from "./auth/Auth";
import { connect } from "react-redux";
import { loadDomain } from "../redux/actions/domain_actions";
import { loadCountries } from "../redux/actions/country_actions";
import { loadUser } from "../redux/actions/user_actions";
import { setBasename } from "../redux/actions/ui_actions";
import { ApolloProvider } from "react-apollo";
import { getPermisoClient } from "../graphql/clients";
import { SUBDOMAIN, stillUrls, bypassLogin } from "../redux/settings";
import Welcome from "./home/welcome";
import Footer from "./home/footer.jsx";
import NotFound from "./shared/NotFound";
import { RotatingPlane } from "better-react-spinkit";
import Notifications from "./shared/Notifications";
import ErrorModal from "./shared/ErrorModal";
import Breadcrumbs from "./shared/Breadcrumbs";
import Homeless from "./home/Homeless";
import SunburstReport from "./reports/SunburstReport";
import CirclePack from "./reports/CirclePackReport";
import LocationMap from "./reports/LocationMap";
import AtAGlance from "./wiki/AtAGlance";
import BusinessTravelResourcesNavPage from "./standalone-pages/BusinessTravelResourcesNavPage";
import CandidateAssessmentPage from "./standalone-pages/CandidateAssessmentPage";
import ImmigrationNewsPage from "./standalone-pages/ImmigrationNewsPage";
import USImmigrationNavPage from "./standalone-pages/USImmigrationNavPage";

import AsyncComponent from "./AsyncComponent";
import CountryPageIndex from "./wiki/CountryPageIndex";
import NavPage from "./shared/navtiles/NavPage";

const AsyncCalendar = AsyncComponent(() => import("./calendar"));
const AsyncConcurImport = AsyncComponent(() => import("./concur_import"));
const AsyncAssessments = AsyncComponent(() => import("./assessments"));
const AsyncAssessmentForm = AsyncComponent(() =>
  import("./assessments/AssessmentForm")
);
const AsyncAssessmentsHome = AsyncComponent(() =>
  import("./assessments/AssessmentsHome")
);
const AsyncAssessmentShow = AsyncComponent(() =>
  import("./assessments/AssessmentShow")
);
const AsyncApplicationResults = AsyncComponent(() =>
  import("./assessments/ApplicationResults")
);
const AsyncProfile = AsyncComponent(() => import("./profile"));
const AsyncOutcomeRules = AsyncComponent(() =>
  import("./domain_admin/OutcomeRules")
);
const AsyncCountryCustomizations = AsyncComponent(() =>
  import("./domain_admin/CountryCustomizations")
);
const AsyncPages = AsyncComponent(() => import("./domain_admin/PageSections"));
const AsyncScreeningQuestions = AsyncComponent(() =>
  import("./domain_admin/ScreeningQuestions")
);
const AsyncSubstitutions = AsyncComponent(() =>
  import("./domain_admin/Substitutions")
);
const AsyncUploads = AsyncComponent(() => import("./domain_admin/Uploads"));
const AsyncDomainAdminAnnouncements = AsyncComponent(() =>
  import("./domain_admin/Announcements")
);
const AsyncPermisoAdminPages = AsyncComponent(() =>
  import("./permiso_admin/PageSections")
);
const AsyncPermisoAdminNews = AsyncComponent(() =>
  import("./permiso_admin/NewsSections")
);
const AsyncDomainAdminSettings = AsyncComponent(() =>
  import("./domain_admin/Settings")
);
const AsyncDomainAdminUnavailableMessages = AsyncComponent(() =>
  import("./domain_admin/UnavailableMessages")
);
const AsyncCountries = AsyncComponent(() =>
  import("./permiso_admin/Countries")
);
const AsyncSpecialGroups = AsyncComponent(() =>
  import("./permiso_admin/SpecialGroups")
);
const AsyncNewCountry = AsyncComponent(() =>
  import("./permiso_admin/NewCountry")
);
const AsyncDomainSettings = AsyncComponent(() =>
  import("./permiso_admin/DomainSettings")
);
const AsyncPermisoAdminAnnouncements = AsyncComponent(() =>
  import("./permiso_admin/Announcements")
);
const AsyncPermisoAdminOutcomeRules = AsyncComponent(() =>
  import("./permiso_admin/AdminOutcomeRules")
);
const AsyncUsageChart = AsyncComponent(() => import("./reports/UsageChart"));
const AsyncDestinationChart = AsyncComponent(() =>
  import("./reports/DestinationChart")
);
const AsyncCustomReport = AsyncComponent(() =>
  import("./reports/CustomReport")
);
const AsyncCustomTaxReport = AsyncComponent(() =>
  import("./reports/CustomTaxReport")
);
const AsyncLocationChart = AsyncComponent(() =>
  import("./reports/LocationChart")
);
const AsyncTaxReportView = AsyncComponent(() =>
  import("./reports/TaxReportView")
);
const AsyncEula = AsyncComponent(() => import("./home/eula"));
const AsyncPrivacy = AsyncComponent(() => import("./home/privacy"));
const AsyncTerms = AsyncComponent(() => import("./home/terms"));
const AsyncConcur = AsyncComponent(() => import("./home/ConcurIntegration"));
const AsyncSupport = AsyncComponent(() => import("./home/support"));
const AsyncCountryTool = AsyncComponent(() =>
  import("./domain_admin/CountryTool")
);
const AsyncBecomeUserForm = AsyncComponent(() =>
  import("./domain_admin/BecomeUserForm")
);
const AsyncUserRoles = AsyncComponent(() =>
  import("./domain_admin/user_roles/UserRoles")
);

const auth = new Auth();

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_loading: false,
      domain_loading: false,
      countries_loading: false,
    };
  }

  static getDerivedStateFromProps(newProps, oldState) {
    let newState = {};
    if (
      newProps.user &&
      !SUBDOMAIN &&
      !/homeless/.exec(window.location.pathname)
    ) {
      window.location.replace("/homeless");
    }
    if (
      !newProps.user &&
      !oldState.user_loading &&
      (localStorage.getItem("id_token") || bypassLogin())
    ) {
      newState.user_loading = true;
      newProps.loadUser(() => (newState.user_loading = false));
    }
    if (
      newProps.user &&
      !newProps.domain &&
      !oldState.domain_loading &&
      SUBDOMAIN
    ) {
      newState.domain_loading = true;
      newProps.loadDomain(() => (newState.domain_loading = false));
    }
    if (
      newProps.user &&
      newProps.domain &&
      !newProps.countries &&
      !oldState.countries_loading
    ) {
      newState.countries_loading = true;
      newProps.loadCountries(() => (newState.countries_loading = false));
    }
    if (newState === {}) {
      return null;
    } else {
      return newState;
    }
  }

  render() {
    const isLoggedIn = auth.isAuthenticated();
    const { user, domain } = this.props;
    if (isLoggedIn && domain && user.accepted_end_user_agreement) {
      const {
        domain: {
          permiso_admin_setting: { feature },
        },
      } = this.props;

      return (
        <ApolloProvider client={getPermisoClient()}>
          <div id="routerRoot">
            <div className="App">
              <TopNav />
              <ErrorModal />
              <Notifications />
              <div
                className="d-flex align-items-start"
                style={{ justifyContent: "start" }}
              >
                <div className="" style={{ flexBasis: "auto" }}>
                  <PermisoSideNav />
                </div>
                <div className="w-100">
                  <div
                    className="pr-5 pl-5"
                    style={{ paddingTop: "20px", flex: 1 }}
                  >
                    {GA.init({ debug: true }) && (
                      <GA.RouteTracker debug={true} />
                    )}
                    <Breadcrumbs />
                    <Switch>
                      <Route
                        exact
                        path="/support"
                        render={(routeProps) => {
                          window.open("https://pearlglobaltech.freshdesk.com");
                          return <Home {...routeProps} domain={domain} />;
                        }}
                      />
                      <Route
                        exact
                        path="/"
                        render={(routeProps) => (
                          <Home {...routeProps} domain={domain} />
                        )}
                      />
                      <Route
                        exact
                        path="/ataglance/:code"
                        render={(routeProps) => (
                          <AtAGlance {...routeProps} domain={domain} />
                        )}
                      />
                      <Route
                        exact
                        path="/ataglance"
                        render={(routeProps) => (
                          <AtAGlance {...routeProps} domain={domain} />
                        )}
                      />
                      <Route
                        exact
                        path="/home"
                        render={(routeProps) => (
                          <Home {...routeProps} domain={domain} />
                        )}
                      />
                      <PrivateRoute
                        exact
                        path="/calendar"
                        component={AsyncCalendar}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/concur_import"
                        component={AsyncConcurImport}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/assessments"
                        component={AsyncAssessments}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/assessments/home"
                        render={() => <AsyncAssessmentsHome />}
                      />
                      <PrivateRoute
                        exact
                        path="/assessments/new"
                        feature_accessible={feature.travel_assessments}
                        render={() =>
                          user.passports.length > 0 &&
                          user.department &&
                          user.country_of_residence_id &&
                          user.immigration_status &&
                          user.job_title ? (
                            <AsyncAssessmentForm />
                          ) : (
                            <Redirect to="/profile" />
                          )
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/assessments/show/:id"
                        component={AsyncAssessmentShow}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/assessments/results/:id"
                        component={AsyncApplicationResults}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/profile"
                        component={AsyncProfile}
                      />
                      <PrivateRoute
                        exact
                        path="/reports"
                        render={() => <Redirect to="/reports/destinations" />}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/outcomes"
                        component={SunburstReport}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/activities_outcome"
                        component={CirclePack}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/location_map"
                        component={LocationMap}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/custom"
                        component={AsyncCustomReport}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/destinations"
                        component={AsyncDestinationChart}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/custom_tax"
                        component={AsyncCustomTaxReport}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.tax_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/tax"
                        component={AsyncTaxReportView}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.tax_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/location"
                        component={AsyncLocationChart}
                        roles={["dev", "admin"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/reports/usage"
                        component={AsyncUsageChart}
                        roles={["dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin"
                        render={() => <Redirect to="/domain_admin/settings" />}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/outcome_rules"
                        component={AsyncOutcomeRules}
                        roles={["admin", "dev"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/customizations"
                        component={AsyncCountryCustomizations}
                        feature_accessible={feature.travel_assessments}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/pages"
                        component={AsyncPages}
                        roles={["admin", "dev"]}
                        feature_accessible={feature.wiki}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/screening_questions"
                        component={AsyncScreeningQuestions}
                        roles={["admin", "dev"]}
                        feature_accessible={feature.travel_assessments}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/uploads"
                        component={AsyncUploads}
                        roles={["admin", "dev"]}
                        feature_accessible={feature.wiki}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/substitutions"
                        component={AsyncSubstitutions}
                        roles={["admin", "dev"]}
                        feature_accessible={feature.wiki}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/settings"
                        component={AsyncDomainAdminSettings}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/become_user"
                        component={AsyncBecomeUserForm}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/user_roles"
                        component={AsyncUserRoles}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/country_tool"
                        component={AsyncCountryTool}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/announcements"
                        component={AsyncDomainAdminAnnouncements}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/domain_admin/unavailable_country_messages"
                        component={AsyncDomainAdminUnavailableMessages}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin"
                        render={() => (
                          <Redirect to="/permiso_admin/countries" />
                        )}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/domain_settings"
                        component={AsyncDomainSettings}
                        roles={["dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/countries"
                        component={AsyncCountries}
                        roles={["dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/special_groups"
                        component={AsyncSpecialGroups}
                        roles={["dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/countries/new"
                        component={AsyncNewCountry}
                        roles={["dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/pages"
                        component={AsyncPermisoAdminPages}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/news"
                        component={AsyncPermisoAdminNews}
                        roles={["admin", "dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/announcements"
                        component={AsyncPermisoAdminAnnouncements}
                        roles={["dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/permiso_admin/outcome_rules"
                        component={AsyncPermisoAdminOutcomeRules}
                        roles={["dev"]}
                      />
                      <PrivateRoute
                        exact
                        path="/wiki"
                        render={() => <Redirect to="/wiki/page/wiki-pc" />}
                        feature_accessible={feature.wiki}
                      />
                      <PrivateRoute
                        exact
                        path="/page/country-info"
                        render={() => <Redirect to="/wiki/country_pages" />}
                      />
                      <Route
                        exact
                        path="/business-travel-resources"
                        render={(routeProps) => (
                          <BusinessTravelResourcesNavPage {...routeProps} />
                        )}
                      />
                      <Route
                        exact
                        path="/candidate-assessment"
                        render={(routeProps) => (
                          <CandidateAssessmentPage {...routeProps} />
                        )}
                      />
                      <Route
                        exact
                        path="/immigration-news"
                        render={(routeProps) => (
                          <ImmigrationNewsPage {...routeProps} />
                        )}
                      />
                      <Route
                        exact
                        path={[
                          "/permiso",
                          "/permiso-reports",
                          "/recruiter-tools",
                          "/traveler-links",
                          "/team_resources",
                        ]}
                        render={(routeProps) => (
                          <NavPage domain={domain} user={user} />
                        )}
                      />
                      <Route
                        exact
                        path="/us-immigration"
                        render={(routeProps) => (
                          <USImmigrationNavPage {...routeProps} />
                        )}
                      />
                      <PrivateRoute
                        exact
                        path="/wiki/page/country-info"
                        render={() => <Redirect to="/wiki/country_pages" />}
                      />
                      <Route
                        exact
                        path="/wiki/country_pages"
                        component={CountryPageIndex}
                        feature_accessible={feature.wiki}
                      />
                      <Route
                        exact
                        path="/country_page/:id"
                        render={(routeProps) => (
                          <Page {...routeProps} type="CountryPage" />
                        )}
                        feature_accessible={feature.wiki}
                      />
                      <Route
                        exact
                        path="/page/:id"
                        component={Page}
                        feature_accessible={feature.wiki}
                      />
                      <Route
                        exact
                        path="/wiki/page/:id"
                        component={Page}
                        feature_accessible={feature.wiki}
                      />
                      <Route exact path="/privacy" component={AsyncPrivacy} />
                      <Route exact path="/terms" component={AsyncTerms} />
                      <Route exact path="/support" component={AsyncSupport} />
                      <Route exact path="/testcallback" component={Callback} />
                      <Route
                        exact
                        path="/concur-connection"
                        component={AsyncConcur}
                      />
                      <Route
                        exact
                        path="/callback"
                        render={(props) => {
                          handleAuthentication(props);
                          return <Callback {...props} />;
                        }}
                      />
                      {stillUrls.map((url, i) => {
                        return (
                          <Route
                            exact
                            key={i}
                            path={url}
                            render={(props) => {
                              return <RotatingPlane size={50} />;
                            }}
                          />
                        );
                      })}
                      <Route component={NotFound} />
                    </Switch>
                  </div>
                  <Footer
                    disclaimer={domain.domain_admin_setting.disclaimer}
                    domainName={domain.name}
                  />
                </div>
              </div>

              <a
                href="https://pearlglobaltech.freshdesk.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  alignItems: "center",
                  backgroundColor: "#c2e1ff",
                  border: "solid 1px #656565",
                  borderRadius: "50%",
                  marginRight: 0,
                  bottom: 10,
                  color: "white",
                  display: "flex",
                  fontSize: 18,
                  height: 40,
                  justifyContent: "center",
                  position: "fixed",
                  right: 10,
                  textAlign: "center",
                  width: 40,
                  zIndex: 99,
                }}
              >
                <i
                  className="fa fa-question"
                  style={{ color: "black", marginRight: 0 }}
                />
              </a>
            </div>
          </div>
        </ApolloProvider>
      );
    } else if (isLoggedIn && !domain) {
      // logged in but not set up yet
      return (
        <Switch>
          <Route exact path="/homeless" component={Homeless} />
          <Route component={Callback} />
        </Switch>
      );
    } else if (isLoggedIn && domain && !user.accepted_end_user_agreement) {
      return <AsyncEula domain={domain} user={user} />;
    } else {
      return (
        <Switch>
          <Route
            exact
            path="/callback"
            render={(props) => {
              handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />
          <Route exact path="/privacy" component={AsyncPrivacy} />
          <Route exact path="/terms" component={AsyncTerms} />
          <Route exact path="/concur-connection" component={AsyncConcur} />
          <Route exact path="/support" component={AsyncSupport} />
          <Route component={Welcome} />
        </Switch>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    domain: state.domain,
    user: state.user,
    countries: state.countries,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadDomain,
    loadCountries,
    loadUser,
    setBasename,
  })(App)
);
