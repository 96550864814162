import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
import Auth from "../../auth/Auth.js";

const auth = new Auth();

class GuestNav extends Component {
  render() {
    return (
      <div className="btn btn-default">
        <FontAwesome name="power-off" />
        <Link to="" onClick={auth.login}>
          Login
        </Link>
      </div>
    );
  }
}

export default GuestNav;
