import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ReactCardFlip from "react-card-flip";

const colors = [
  "#19988b",
  "#3b8ede",
  "#1cd4c6",
  "#0337dd",
  "#7baed4",
  "#007481",
  "#021686",
  "#898989",
];

function OneTile({ tileInfo, flipped, setFlipped, idx, history }) {
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    if (!tileInfo.description) {
      navigateToLink();
    } else {
      setFlipped(!flipped);
    }
  };

  const navigateToLink = () => {
    if (tileInfo.link.startsWith("http")) {
      window.confirm("You are about to be redirected outside of Pearl Hub") &&
        window.open(tileInfo.link, "_blank");
    } else {
      history.push(tileInfo.link);
    }
  };

  return (
    <div
      style={{ display: "inline-flex" }}
      className={hovered ? "hover_grow" : "hover_shrink"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ReactCardFlip isFlipped={flipped}>
        <div
          className="new_nav_tile"
          style={{ backgroundColor: colors[idx % colors.length] }}
          onClick={handleClick}
        >
          {tileInfo.icon && (
            <img
              src={tileInfo.icon}
              alt={tileInfo.title}
              style={{ height: 50, marginBottom: 10 }}
            />
          )}
          <p className="title">{tileInfo.title}</p>
        </div>
        <div
          className="new_nav_tile"
          style={{ backgroundColor: colors[idx % colors.length] }}
          onClick={handleClick}
        >
          <p className="desc">{tileInfo.description}</p>
          <a onClick={navigateToLink}>Go</a>
        </div>
      </ReactCardFlip>
    </div>
  );
}

function NavTiles({ tiles, history }) {
  const [flippedTile, setFlippedTile] = useState(null);

  return (
    <React.Fragment>
      <div className="container-fluid w-100 nav-tiles">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
          {tiles.map((tile, idx) => (
            <div className="col justify-content-center mt-5" key={idx}>
              <OneTile
                tileInfo={tile}
                idx={idx}
                flipped={flippedTile === tile.id}
                setFlipped={(flipped) =>
                  setFlippedTile(flipped ? tile.id : null)
                }
                history={history}
              />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(NavTiles);
