import React, { Component } from "react";
import WidgetBox from "../shared/WidgetBox";
import { Chart } from "react-google-charts";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { BigLoadingThing } from "../shared/LoadingThing";
import { Query } from "react-apollo";

class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      options: null,
      data: null,
      showCountry: null,
    };
  }

  selectHandler = (Chart) => {
    this.setState({
      showCountry:
        this.state.data[Chart.chart.getSelection()[0].row].country.id,
    });
  };

  updateData = () => {
    const data = this.props.data.current_locations;
    const named = data.map((c) => {
      return { country: c.country, count: c.count, id: c.id };
    });
    this.setState({
      data: named,
      chartData: {
        data: [
          ["Country", "Travelers"],
          ...named.map((n) => {
            return [n.country.name, n.count];
          }),
        ],
      },
    });
  };

  loadCountryQuery = gql`
    query locate_users($country_id: ID!) {
      country(id: $country_id) {
        name
      }
      locate_users(country_id: $country_id) {
        id
        name
      }
    }
  `;

  render() {
    const UserList = ({ country_id }) => (
      <Query query={this.loadCountryQuery} variables={{ country_id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          return (
            <div className="mt-3" style={{ borderTop: "thin lightgray solid" }}>
              <h2>Travelers in {data.country.name}</h2>
              <div className="name-box d-flex showChart">
                {data.locate_users.map((u, i) => {
                  return (
                    <div
                      className="m-0 p-2"
                      style={{
                        flex: "0 0 33.3%",
                        border: "thin lightgray solid",
                      }}
                    >
                      {u.name}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      </Query>
    );
    const options = {
      displayMode: "region",
      colorAxis: { colors: ["blue", "maroon"] },
    };
    if (this.props.data.loading) {
      return <BigLoadingThing />;
    } else if (this.state.chartData) {
      return (
        <WidgetBox label="Current Traveler Locations by Travel Plans">
          <div>
            <Chart
              chartType="GeoChart"
              width="100%"
              height="500px"
              data={this.state.chartData.data}
              options={options}
              graph_id="GeoChart"
              mapsApiKey="AIzaSyBxXYr_EUZsxKI3IcjoXn5Owl9cEqdfLNs"
              legend_toggle={false}
              chartEvents={[
                {
                  eventName: "select",
                  callback: (Chart) => {
                    this.selectHandler(Chart);
                  },
                },
              ]}
            />
            {this.state.showCountry && (
              <UserList country_id={this.state.showCountry} />
            )}
          </div>
        </WidgetBox>
      );
    } else {
      this.updateData();
      return <BigLoadingThing />;
    }
  }
}

const DomainsQuery = gql`
  query LocationQuery {
    current_locations {
      country {
        id
        name
      }
      count
    }
  }
`;

const connectedComponent = graphql(DomainsQuery)(LocationMap);

export default connectedComponent;
