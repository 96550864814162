import { ApolloClient } from "apollo-client/index";
import { InMemoryCache } from "apollo-cache-inmemory/lib/index";
import { GRAPHQL_URL, CMS_URL_BASE } from "../redux/settings";
import { createHttpLink } from "apollo-link-http/lib/index";

export const getPermisoClient = () => {
  const id_token = localStorage.getItem("id_token");
  const principal_id = localStorage.getItem("principal_id");
  const headers = {
    authorization: id_token ? `Bearer ${id_token}` : null,
    "X-PERMISO-IdToken": id_token,
    "X-PERMISO-Principal": principal_id,
  };
  const httpLink = new createHttpLink({
    uri: GRAPHQL_URL,
    headers: headers,
  });

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  });
};

export const CMS_CONTENT_URL = `${CMS_URL_BASE}/nodes/`;

export const getCMSClient = () => {
  const MESH_URL = `${CMS_URL_BASE}/graphql/`;
  const headers = {};
  const httpLink = new createHttpLink({
    uri: MESH_URL,
    headers: headers,
  });

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  });
};
