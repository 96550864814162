import { dispatchResults } from "./app_actions";
import store from "../store";
import axiosApi from "../axiosApi";
import alertApiError from "../../helpers/alert_api_error";
import { ROOT_URL, getSubdomain } from "../settings";
import * as actionTypes from "../action-types";

export function loadDomain(callback = () => {}) {
  const subdomain = getSubdomain();
  var domainId;
  console.log(store.getState());
  if (subdomain === "www") {
    domainId = store.getState().user.domain_id;
  } else {
    domainId = subdomain;
  }
  const url = `${ROOT_URL}/domains/${domainId}`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.SET_BASENAME,
        payload: `/domains/${result.data.domain_key}`,
      });
      dispatchResults({
        type: actionTypes.LOAD_DOMAIN,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_DOMAIN,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_DOMAIN,
      });
      console.log(error);
      alertApiError(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_DOMAIN };
}

export function loadDomainAgents(domain_id, callback = () => {}) {
  const url = `${ROOT_URL}/domains/${domain_id}/agents`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_DOMAIN_AGENTS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_DOMAIN_AGENTS,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_DOMAIN_AGENTS,
      });
      console.log(error);
      alertApiError(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_DOMAIN_AGENTS };
}

export function loadDomainApplications(
  domain_id,
  arr,
  dep,
  callback = () => {}
) {
  const arrive = arr || "2017-01-01";
  const depart = dep || "2017-12-31";
  const url = `${ROOT_URL}/domain_admin/domains/${domain_id}/applications?arrive=${arrive}&depart=${depart}`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_DOMAIN_APPLICATIONS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_DOMAIN_APPLICATIONS,
      });
      callback(result);
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_DOMAIN_APPLICATIONS,
      });
      console.log(error);
      alertApiError(error);
    });
  return {
    type: actionTypes.LOADING,
    payload: actionTypes.LOAD_DOMAIN_APPLICATIONS,
  };
}
