import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "./Auth.js";
import { connect } from "react-redux";
import _ from "lodash";
import { addFlashMessage } from "../../redux/actions/ui_actions";

const auth = new Auth();

const PrivateRoute = ({
  component: Component,
  render: renderFunction,
  roles,
  user,
  domain,
  feature_accessible,
  ...rest
}) => {
  feature_accessible =
    feature_accessible === undefined ? true : feature_accessible;
  return feature_accessible ? (
    renderFunction ? (
      <Route
        {...rest}
        render={(props) => {
          if (auth.isAuthenticated()) {
            if (roles) {
              if (_.includes(roles, user.role)) {
                return renderFunction();
              } else {
                addFlashMessage(
                  "unauthorized_role",
                  "error",
                  "You are not authorized to access that area"
                );
                return <Redirect to="/home" />;
              }
            } else {
              return renderFunction();
            }
          } else {
            auth.login();
            return null;
          }
        }}
      />
    ) : (
      <Route
        {...rest}
        render={(props) => {
          if (auth.isAuthenticated()) {
            if (roles) {
              if (_.includes(roles, user.role)) {
                return <Component {...props} />;
              } else {
                addFlashMessage(
                  "unauthorized_role",
                  "error",
                  "You are not authorized to access that area"
                );
                return <Redirect to="/home" />;
              }
            } else {
              return <Component {...props} />;
            }
          } else {
            auth.login();
            return null;
          }
        }}
      />
    )
  ) : (
    <Redirect to="/home" />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    domain: state.user,
  };
};

const connectedComponent = connect(mapStateToProps)(PrivateRoute);

export default connectedComponent;
