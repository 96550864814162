import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger";
import user_reducer from "./reducers/user_reducer";
import announcement_reducer from "./reducers/announcement_reducer";
import domain_reducer from "./reducers/domain_reducer";
import domain_announcement_reducer from "./reducers/domain/announcement_reducer";
import domain_admin_settings_reducer from "./reducers/domain_admin_settings_reducer";
import domain_admin_announcement_reducer from "./reducers/domain_admin/announcement_reducer";
import domain_unavailable_message_reducer from "./reducers/domain/unavailable_message_reducer";
import domain_activity_reducer from "./reducers/domain/activity_reducer";
import page_reducer from "./reducers/page_reducer";
import screening_question_reducer from "./reducers/screening_question_reducer";
import upload_reducer from "./reducers/upload_reducer";
import country_page_addition_reducer from "./reducers/country_page_addition_reducer";
import substitution_reducer from "./reducers/substitution_reducer";
import permiso_admin_page_reducer from "./reducers/permiso_admin/page_reducer";
import permiso_admin_news_reducer from "./reducers/permiso_admin/news_reducer";
import ReduxPromise from "redux-promise";
import Cookies from "js-cookie";
import { createCookieMiddleware } from "redux-cookie";
import { initialState } from "./initial_state";
import { reducer as form_reducer } from "redux-form";
import country_reducer from "./reducers/country_reducer";
import app_reducer from "./reducers/app_reducer";
import permiso_admin_activity_reducer from "./reducers/permiso_admin/activity_reducer";
import permiso_admin_country_reducer from "./reducers/permiso_admin/country_reducer";
import permiso_admin_special_group_reducer from "./reducers/permiso_admin/special_group_reducer";
import permiso_admin_announcement_reducer from "./reducers/permiso_admin/announcement_reducer";
import permiso_admin_outcome_rule_reducer from "./reducers/permiso_admin/outcome_rule_reducer";
import customization_reducer from "./reducers/customization_reducer";
import report_reducer from "./reducers/report_reducer";
import assessment_reducer from "./reducers/assessment_reducer";
import ui_reducer from "./reducers/ui_reducer";
import concur_reducer from "./reducers/concur_reducer";

const reducers = {
  ui: ui_reducer,
  user: user_reducer,
  announcements: announcement_reducer,
  domain: domain_reducer,
  domain_announcements: domain_announcement_reducer,
  domain_admin_settings: domain_admin_settings_reducer,
  domain_admin_announcements: domain_admin_announcement_reducer,
  unavailable_country_messages: domain_unavailable_message_reducer,
  pages: page_reducer,
  screening_questions: screening_question_reducer,
  uploads: upload_reducer,
  country_page_additions: country_page_addition_reducer,
  substitutions: substitution_reducer,
  countries: country_reducer,
  activities: domain_activity_reducer,
  permiso_admin_country: permiso_admin_country_reducer,
  permiso_admin_activities: permiso_admin_activity_reducer,
  permiso_admin_pages: permiso_admin_page_reducer,
  permiso_admin_news: permiso_admin_news_reducer,
  permiso_admin_special_groups: permiso_admin_special_group_reducer,
  permiso_admin_announcements: permiso_admin_announcement_reducer,
  permiso_admin_outcome_rules: permiso_admin_outcome_rule_reducer,
  applications: app_reducer,
  rules: customization_reducer,
  report_data: report_reducer,
  report_download: assessment_reducer,
  concur: concur_reducer,
  form: form_reducer,
};
const reducer = combineReducers(reducers);
export default createStore(
  reducer,
  initialState,
  applyMiddleware(ReduxPromise, createCookieMiddleware(Cookies), logger)
);
