import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div className="d-flex w-100 h-100 align-content-around justify-content-around">
        <div>Whatever you seek cannot be found here.</div>
      </div>
    );
  }
}

export default NotFound;
