import axios from "axios";
import { bypassLogin } from "./settings";

class axiosApi {
  static defaultOptions = () => {
    let headers = {};
    if (bypassLogin()) {
      headers = {
        headers: {
          Test: true,
        },
      };
    } else {
      headers = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "X-PERMISO-IdToken": localStorage.getItem("id_token"),
          "X-PERMISO-Principal": localStorage.getItem("principal_id"),
        },
      };
    }
    return headers;
  };

  static get = (url, options = {}) =>
    axios.get(url, { ...axiosApi.defaultOptions(), ...options });
  static post = (url, data, options = {}) =>
    axios.post(url, data, { ...axiosApi.defaultOptions(), ...options });
  static put = (url, data, options = {}) =>
    axios.put(url, data, { ...axiosApi.defaultOptions(), ...options });
  static patch = (url, data, options = {}) =>
    axios.patch(url, data, { ...axiosApi.defaultOptions(), ...options });
  static del = (url, options = {}) =>
    axios.delete(url, { ...axiosApi.defaultOptions(), ...options });
}

export default axiosApi;
