import React, { Component } from "react";
import TopRightNav from "./top_right_nav";
import { connect } from "react-redux";
import { RotatingPlane } from "better-react-spinkit";

class TopNav extends Component {
  showSpinner = () => {
    let spinner = [];
    for (let i = 0; i < this.props.loading.length; i++) {
      spinner.push(<RotatingPlane size={10} key={i} className="ml-2" />);
    }
    return spinner;
  };
  render() {
    if (this.props.domain) {
      const {
        domain: {
          domain_admin_setting: { domain_ui_setting: img },
        },
      } = this.props;
      let logo = "";
      const styles = () => {
        let basic = {
          background: "lightgray",
          boxShadow: "0 2px 5px #bbb",
          zIndex: 99,
        };
        // if(img && img.nav_style) {
        //   const match = /.*background: ?(#\w{6})/.exec(img.nav_style);
        //   if(match && match[1])
        //   basic.background = match[1]
        // }
        return basic;
      };
      if (img) {
        if (/https?:\/\//.exec(img.img)) {
          logo = img.img.replace("http:", window.location.protocol);
        } else {
          logo = `https://permiso.me${img.img}`;
        }
      } else {
        logo = "https://permiso.me/portal/logos/Permiso.png";
      }

      return (
        <div
          className="navbar navbar-toggleable-sm"
          id="navbar"
          style={styles()}
        >
          <img
            alt="logo"
            src={logo}
            onClick={() => {
              window.location = "/home";
            }}
            style={{
              borderRadius: "0",
              margin: "0",
              width: "170px",
              padding: "0px",
              cursor: "pointer",
            }}
            className="navbar-brand"
          />
          {this.showSpinner()}
          <ul className="navbar-nav" style={{ alignSelf: "right" }}>
            <TopRightNav />
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    domain: state.domain,
    loading: state.ui.loading,
  };
};

export default connect(mapStateToProps)(TopNav);
