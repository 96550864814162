import { dispatchResults } from "./app_actions";
import * as actionTypes from "../action-types";

export function toggleMenu() {
  return { type: actionTypes.TOGGLE_MENU, payload: null };
}

export function setLoading(action) {
  return { type: actionTypes.LOADING, payload: action };
}

export function setComplete(action) {
  return { type: actionTypes.COMPLETE, payload: action };
}

export function setBasename(action) {
  return { type: actionTypes.SET_BASENAME, payload: action };
}

export function addFlashMessage(id, type, message, dispatch = true) {
  const body = {
    type: actionTypes.ADD_FLASH_MESSAGE,
    payload: {
      id: id,
      type: actionTypes,
      message: message,
      dismissAfter: 2000,
      action: "Dismiss",
      key: id,
      onClick: () => dispatchResults(removeFlashMessage({ id: id })),
    },
  };
  if (dispatch) {
    dispatchResults(body);
  } else {
    return body;
  }
}

export function removeFlashMessage(notification) {
  return {
    type: actionTypes.REMOVE_FLASH_MESSAGE,
    payload: notification.id,
  };
}

export function addErrorMessage(id, message) {
  dispatchResults({
    type: actionTypes.ADD_ERROR_MESSAGE,
    payload: {
      id: id,
      content: message,
    },
  });
}

export function clearErrorMessages() {
  return {
    type: actionTypes.CLEAR_ERROR_MESSAGES,
    payload: [],
  };
}
