import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../auth/Auth";

const auth = new Auth();

class Welcome extends Component {
  render() {
    return (
      <div id="welcome-container">
        <div id="welcome-logo-container">
          <img
            src={
              "https://www.topia.com/wp-content/uploads/2019/12/topia-logo.svg"
            }
            alt="Logo"
            id="main-logo"
          />
        </div>
        <div id="welcome-button-div">
          <h1
            id="welcome-button-label"
            className="mx-auto text-center"
            style={{ width: "200px" }}
          >
            Welcome
          </h1>
          <Link className="btn" id="welcome-button" to="" onClick={auth.login}>
            Login
          </Link>
        </div>
      </div>
    );
  }
}

export default Welcome;
