import React, { useState, useEffect } from "react";
import { ROOT_URL } from "../../redux/settings";
import axiosApi from "../../redux/axiosApi";
import alertApiError from "../../helpers/alert_api_error";
import { withRouter } from "react-router-dom";
import { BigLoadingThing } from "../shared/LoadingThing";
import { Token, Typeahead, TypeaheadMenu } from "react-bootstrap-typeahead";
import CountryDetailCard from "./CountryDetailCard";
import { validate } from "graphql";

const categories = [
  {
    key: "bv_full_summary",
    label: "Full Summary",
    className: "font-weight-bold",
    disabled: true,
  },
  { key: "bv_all", label: "Show All", className: "ml-2" },
  {
    key: "bv",
    label: "Business Visitors",
    className: "font-weight-bold",
    disabled: true,
  },
  { key: "bv_overview", label: "Overview", className: "ml-2" },
  {
    key: "bv_allowable_activities",
    label: "Allowable Activities",
    className: "ml-2",
  },
  {
    key: "bv_basic_requirements_introduction",
    label: "Basic Requirements",
    className: "ml-2",
    childKeys: ["bv_basic_requirements"],
  },
  {
    key: "bv_maximum_period_of_stay",
    label: "Maximum Period of Stay",
    className: "ml-2",
  },
  {
    key: "bv_application_timeline",
    label: "Application Timeline",
    className: "ml-2",
  },
  {
    key: "wa",
    label: "Work Authorization",
    className: "font-weight-bolder",
    disabled: true,
  },
  {
    key: "wa_work_permit_types_internal",
    label: "Work Permit Types",
    className: "ml-2",
  },
  {
    key: "wa_work_permit_process",
    label: "Work Permit Process",
    className: "ml-2",
  },
  {
    key: "wa_maximum_period_of_stay_internal",
    label: "Maximum Period of Stay",
    className: "ml-2",
  },
  {
    key: "wa_application_timeline",
    label: "Application Timeline",
    className: "ml-2",
  },
  { key: "wa_dependents", label: "Dependents", className: "ml-2" },
  {
    key: "rf",
    label: "Red Flags and Covid-19",
    className: "font-weight-bolder",
    disabled: true,
  },
  { key: "red_flags", label: "Red Flags", className: "ml-2" },
  {
    key: "covid19_internal",
    label: "COVID-19 Restrictions",
    className: "ml-2",
  },
  {
    key: "oi",
    label: "Other",
    className: "font-weight-bolder",
    disabled: true,
  },
  { key: "oi_helpful_links", label: "Helpful Links", className: "ml-2" },
  {
    key: "oi_commonly_requested_documents",
    label: "Commonly Requested Documents",
    className: "ml-2",
  },
  {
    key: "oi_global_immigration_faqs",
    label: "Global Immigration FAQs",
    className: "ml-2",
  },
  { key: "oi_dos_and_donts", label: "Dos and Don'ts", className: "ml-2" },
  {
    key: "oi_trusted_traveler_options",
    label: "Trusted Traveler Options",
    className: "ml-2",
  },
];

function SearchKnowledgeBase(props) {
  const [pages, setPages] = useState(null);
  const [filteredPages, setFilteredPages] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedHighlights, setSelectedHighlights] = useState([]);
  const [countryInvalid, setCountryInvalid] = useState(false);
  const [input, setInput] = useState("");

  const loadPages = () => {
    const url = `${ROOT_URL}/country_details_full`;
    axiosApi
      .get(url)
      .then((result) => {
        return result.data;
      })
      .then((pages) => {
        return pages.filter((p) => {
          return p.data.country_name;
        });
      })
      .then((pages) => {
        setPages(
          pages.sort(
            (a, b) => (a.data.country_name > b.data.country_name ? 1 : -1),
            []
          )
        );
      })
      .catch((error) => {
        console.log(error);
        alertApiError(error);
      });
  };

  useEffect(() => {
    pages || loadPages();
  });

  useEffect(() => {
    if (selectedCountries.length === 0) {
      setFilteredPages([]);
      return () => {};
    } // has a category selected
    if (selectedTopics.length === 0) {
      setFilteredPages([]);
      return () => {};
    } // has a country selected

    setFilteredPages(
      pages.filter((page) => selectedCountries.some((f) => f.url === page.url))
    );
  }, [selectedCountries, selectedTopics, selectedHighlights]);

  const validateCountry = (country) => {
    setCountryInvalid(country && country.length > 0);
  };

  const handleInputChange = (e) => {
    if (e.keyCode === 13) {
      document.querySelectorAll("#toggle-example-item-0")[0].click();
    }
  };

  if (pages) {
    return (
      <React.Fragment>
        <div className="row kb-search">
          <div
            id="value"
            value={input}
            className="mt-3 ml-3"
            style={{ flexGrow: 2 }}
          >
            <Typeahead
              filterBy={(option, props) => {
                if (props.text.length > 2) {
                  setTimeout(
                    () =>
                      setCountryInvalid(
                        !pages.find((p) =>
                          p.data.country_name
                            .toLowerCase()
                            .includes(props.text.toLowerCase())
                        )
                      ),
                    1
                  );
                  return option.label
                    .toLowerCase()
                    .includes(props.text.toLowerCase());
                }
                setTimeout(() => setCountryInvalid(), 1);
                return true;
              }}
              multiple
              highlightOnlyResult
              options={pages.map((p) => {
                return { url: p.url, label: p.data.country_name };
              })}
              placeholder="Start typing country name(s)"
              onChange={(selectedFilters) => {
                if (selectedFilters.length > selectedCountries.length) {
                  try {
                    document.querySelector("#kb-search-topic input").focus();
                  } catch (e) {
                    // ignore this
                  }
                }
                setSelectedCountries(selectedFilters);
              }}
              onBlur={(e) => validateCountry(e.target.value)}
              renderMenu={(results, menuProps) => {
                // Hide the menu when there are no results.
                if (!results.length) {
                  return null;
                }
                return (
                  <TypeaheadMenu
                    {...menuProps}
                    options={results}
                    labelKey="label"
                    text={menuProps.text}
                  />
                );
              }}
              renderToken={(option, props, index) => {
                return (
                  <Token
                    disabled={props.disabled}
                    key={index}
                    onRemove={props.onRemove}
                    option={option}
                    className="badge-light rounded"
                    tabIndex={props.tabIndex}
                  >
                    {option.label}
                  </Token>
                );
              }}
              className="w-100"
            ></Typeahead>
            {countryInvalid && (
              <span className="text-primary">Coming soon!...</span>
            )}
          </div>
          <div
            id="kb-search-topic"
            className="mt-3 ml-3"
            style={{ flexGrow: 3 }}
          >
            <Typeahead
              filterBy={(option, props) => {
                return option.label
                  .toLowerCase()
                  .includes(props.text.toLowerCase());
              }}
              multiple
              highlightOnlyResult
              options={categories}
              placeholder="Popular Topic(s)"
              onChange={(selectedFilters) => {
                if (selectedFilters.length > selectedTopics.length) {
                  try {
                    document
                      .querySelector("#kb-search-highlight input")
                      .focus();
                  } catch (e) {
                    // ignore this
                  }
                }
                if (selectedFilters.find((st) => st.label === "Show All")) {
                  setSelectedTopics(categories);
                } else {
                  setSelectedTopics(selectedFilters);
                }
              }}
              renderMenu={(results, menuProps) => {
                // Hide the menu when there are no results.
                if (!results.length) {
                  return null;
                }
                return (
                  <TypeaheadMenu
                    {...menuProps}
                    options={results}
                    labelKey="label"
                    text={menuProps.text}
                  />
                );
              }}
              renderMenuItemChildren={(option, { text }, index) => (
                <React.Fragment>
                  <div className={option.className}>{option.label}</div>
                </React.Fragment>
              )}
              renderToken={(option, props, index) => {
                return (
                  <Token
                    disabled={props.disabled}
                    key={index}
                    onRemove={props.onRemove}
                    option={option}
                    highlightOnlyResult
                    className="badge-light rounded"
                    tabIndex={props.tabIndex}
                  >
                    {option.label}
                  </Token>
                );
              }}
              className="w-100"
            ></Typeahead>
          </div>
          <div
            id="kb-search-highlight"
            className="mt-3 ml-3"
            style={{ flexGrow: 6 }}
          >
            <Typeahead
              filterBy={(option, props) => {
                return option.label
                  .toLowerCase()
                  .includes(props.text.toLowerCase());
              }}
              id="toggle-example"
              multiple
              options={[]}
              allowNew
              onKeyDown={handleInputChange}
              newSelectionPrefix="Highlight: "
              placeholder="Optional: Text to Highlight"
              onChange={(selectedFilters) =>
                setSelectedHighlights(selectedFilters)
              }
              renderMenu={(results, menuProps) => {
                // Hide the menu when there are no results.
                if (!results.length) {
                  return null;
                }
                if (
                  selectedHighlights &&
                  selectedCountries &&
                  selectedTopics.length == 0
                ) {
                  setSelectedTopics(categories);
                }
                return (
                  <TypeaheadMenu
                    {...menuProps}
                    options={results}
                    labelKey="label"
                    text={menuProps.text}
                  />
                );
              }}
              renderToken={(option, props, index) => {
                return (
                  <Token
                    disabled={props.disabled}
                    key={index}
                    onRemove={props.onRemove}
                    option={option}
                    className="badge-light rounded"
                    tabIndex={props.tabIndex}
                  >
                    {option.label}
                  </Token>
                );
              }}
              className="w-100"
            ></Typeahead>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {filteredPages.map((page) => (
              <CountryDetailCard
                page={page}
                selectedCategories={selectedTopics}
                highlightTexts={selectedHighlights}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <BigLoadingThing />;
  }
}

export default withRouter(SearchKnowledgeBase);
