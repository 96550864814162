import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { select, pack, scaleLinear, hierarchy, interpolateHcl } from "d3";
//import importData from '../../data/circle_data';

class CirclePack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: this.load,
    };
  }
  // D3 Stuff
  initD3 = () => {
    this.vis = select(findDOMNode(this)).insert("svg:svg").append("svg:g");
    this.circlesGroup = this.vis.append("g");
    this.textsGroup = this.vis.append("g");
    this.tooltip = select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("background-color", "white")
      .style("padding", "8px")
      .style("border", "thin black solid")
      .style("max-width", "240px")
      .style("visibility", "hidden");
    this.color = scaleLinear()
      .domain([-1, 3])
      .range(["hsl(234, 77%, 18%)", "hsl(44, 46%, 60%)"])
      .interpolate(interpolateHcl);
    this.pack = pack();
    this.update();
    this.load(this.props);

    // select(window).on("click", function () {
    //   this.zoom( this.data );
    // }.bind(this));

    // var ns = Math.random();
    // select(window).on('resize.' + ns, this.resizeHandler);
  };

  update = () => {
    //const parentNode =  select(findDOMNode(this).parentElement );
    //const parentWidth = parentNode._groups[0].offsetWidth;
    this.w = this.h = this.r = 500;

    this.x = scaleLinear().range([0, this.r]);
    this.y = scaleLinear().range([0, this.r]);

    this.r = this.w < this.h ? this.w : this.h;

    this.x = scaleLinear().range([0, this.r]);
    this.y = scaleLinear().range([0, this.r]);

    select(findDOMNode(this))
      .select("svg")
      .attr("width", this.w)
      .attr("height", this.h)
      .select("g")
      .attr(
        "transform",
        "translate(" + (this.w - this.r) / 2 + "," + (this.h - this.r) / 2 + ")"
      );

    this.pack.size([this.r, this.r]);
    if (this.data) {
      this.nodes = this.pack.nodes(this.data);
    }
  };

  // Load JSON
  load = (props) => {
    this.data = props.data;
    this.root = hierarchy(this.data).sum((d) => {
      return d.size;
    });
    this.nodes = this.root.descendants();
    this.pack(this.root);
    this.draw(props);
  };

  // D3 Layout (enter-update-exit pattern)
  draw = (props) => {
    const startDelay = props.startDelay || 0,
      elementDelay = props.elementDelay || 5;

    const circles = this.circlesGroup.selectAll("circle").data(this.nodes);

    circles
      .enter()
      .append("svg:circle")
      .attr("class", (d) => (d.children ? "parent" : "child"))
      .style("stroke", (d) => (d.children ? "white" : "black"))
      .style("stroke-width", (d) => (d.children ? "0px" : "0.25px"))
      .style("fill", (d) => (d.children ? this.color(d.depth) : "white"))
      .style("opacity", (d) => (d.children ? 1 : 1))
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .attr("r", () => 0)
      .on("click", (d) => {
        this.props.onSelect && this.props.onSelect(d);
        return this.zoom(this.data === d ? this.data : d);
      })
      .on("mouseover", (d) => {
        let tt;
        if ("fullName" in d.data) {
          tt = d.data.fullName;
        } else {
          tt = d.data.name;
        }
        return this.tooltip.text(tt).style("visibility", "visible");
      })
      .on("mousemove", (event) =>
        this.tooltip
          .style("top", event.pageY - 10 + "px")
          .style("left", event.pageX + 10 + "px")
      )
      .on("mouseout", () => this.tooltip.style("visibility", "hidden"))
      .transition()
      .duration(400);

    circles
      .transition()
      .duration(400)
      .delay(function (d, i) {
        return startDelay + i * elementDelay;
      })
      .attr("class", function (d) {
        return d.children ? "parent" : "child";
      })
      .attr("cx", function (d) {
        return d.x;
      })
      .attr("cy", function (d) {
        return d.y;
      })
      .attr("r", function (d) {
        return d.r;
      });

    circles
      .exit()
      .transition()
      .duration(200)
      .attr("r", 0)
      .style("opacity", 0)
      .remove();

    const texts = this.textsGroup.selectAll("text").data(this.nodes);

    texts
      .enter()
      .append("svg:text")
      .style("opacity", 0)
      .attr("x", function (d) {
        return d.x;
      })
      .attr("y", function (d) {
        return d.y;
      })
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .style("font-weight", (d) => (d.children ? "bold" : "normal"))
      .transition()
      .duration(400);

    texts
      .transition()
      .duration(400)
      .attr("class", function (d) {
        return d.children ? "parent" : "child";
      })
      .attr("x", function (d) {
        return d.x;
      })
      .attr("y", function (d) {
        return d.y;
      })
      .delay(function (d, i) {
        return startDelay + i * elementDelay;
      })
      .style("opacity", function (d) {
        return d.r > 50 ? 1 : 0;
      })
      .text(function (d) {
        if (d.depth === 1) {
          return d.data.name;
        } else {
          return "";
        }
      });

    texts.exit().transition().duration(200).style("opacity", 0).remove();
  };

  zoom = (event, node, i) => {
    const k = this.r / node.r / 2;
    this.x.domain([node.x - node.r, node.x + node.r]);
    this.y.domain([node.y - node.r, node.y + node.r]);

    const t = this.vis.transition().duration(event.altKey ? 7500 : 750);

    t.selectAll("circle")
      .attr(
        "cx",
        function (d) {
          return this.x(d.x);
        }.bind(this)
      )
      .attr(
        "cy",
        function (d) {
          return this.y(d.y);
        }.bind(this)
      )
      .attr("r", function (d) {
        return k * d.r;
      });

    t.selectAll("text")
      .attr(
        "x",
        function (d) {
          return this.x(d.x);
        }.bind(this)
      )
      .attr(
        "y",
        function (d) {
          return this.y(d.y);
        }.bind(this)
      )
      .text(function (d) {
        if (
          node.depth === d.depth - 1 ||
          (d.children === undefined && d.depth === node.depth)
        ) {
          return d.data.name;
        } else {
          return "";
        }
      })
      .style("opacity", function (d) {
        return k * d.r > 50 ? 1 : 0;
      });

    event.stopPropagation();
  };

  resizeHandler = () => {
    clearInterval(this.resizeTimer);
    this.resizeTimer = setTimeout(
      function () {
        this.update();
        this.draw(this.props);
      }.bind(this),
      200
    );
  };

  componentDidMount() {
    this.initD3();
    this.props.toggle();
  }

  componentWillUnmount() {
    clearInterval(this.resizeTimer);
  }

  componentWillReceiveProps(props) {
    this.load(props);
  }

  render() {
    return <div height={500} width={500}></div>;
  }
}

export default CirclePack;
