import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import ReactMarkdown from "react-markdown";
import { BigLoadingThing } from "../shared/LoadingThing";
import { loadAnnouncements } from "../../redux/actions/announcement_actions";
import { domainLoadAnnouncements } from "../../redux/actions/domain/announcement_actions";
import { loadDashboardTilesVisibility } from "../../redux/actions/user_actions";
import SearchKnowledgeBase from "../search_knowledgebase";
import NavPage from "../shared/navtiles/NavPage";

class Dashboard extends Component {
  componentDidMount() {
    const { user } = this.props;
    loadAnnouncements();
    domainLoadAnnouncements(user.domain_id);
    loadDashboardTilesVisibility();
  }

  render() {
    const { domain, domain_announcements, system_announcements, user } =
      this.props;
    if (!domain || !domain_announcements || !system_announcements) {
      return <BigLoadingThing />;
    }

    const {
      permiso_admin_setting: { feature },
    } = domain;

    return (
      <div style={{ marginTop: "3px" }} className="dashboard">
        {system_announcements.length > 10 && (
          <div className="text-left">
            <div className="alert adjusted alert-dark w-100">
              <FontAwesome name="gears" className="mr-2" />
              <span>
                <span className="h4">System Announcements</span>
                <div style={{ padding: "10px 0" }} className="center">
                  {system_announcements.map((announcement, i) => {
                    return (
                      <ReactMarkdown
                        key={i}
                        source={announcement.message}
                        escapeHtml={false}
                      />
                    );
                  })}
                </div>
              </span>
            </div>
          </div>
        )}
        {domain_announcements.length > 0 && (
          <div className="text-left">
            <div className="alert adjusted alert-warning w-100">
              <FontAwesome name="bullhorn" className="mr-2" />
              <span>
                <span className="h4">Announcements</span>
                <div style={{ padding: "10px 0" }} className="center">
                  {domain_announcements.map((announcement, i) => {
                    return (
                      <ReactMarkdown
                        key={i}
                        source={announcement.message}
                        escapeHtml={false}
                      />
                    );
                  })}
                </div>
              </span>
            </div>
          </div>
        )}

        <div className="container-fluid h-100">
          <div className="row">
            <div className="col text-left">
              <h2>Welcome! What do you want to know?</h2>
            </div>
          </div>
          <SearchKnowledgeBase />
          <div className="row">
            <div className="col">
              <NavPage domain={domain} user={user} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    system_announcements: state.announcements,
    domain_announcements: state.domain_announcements,
    dashboard_tiles_visibility: state,
  };
};
export default connect(mapStateToProps, {
  loadAnnouncements,
  domainLoadAnnouncements,
  loadDashboardTilesVisibility,
})(Dashboard);
