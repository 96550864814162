import { initialState } from "../initial_state";
import update from "immutability-helper";
import _ from "lodash";
import * as actionTypes from "../action-types";

export default (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.COMPLETE:
      newState = update(state, {
        loading: { $splice: [[state.loading.indexOf(action.payload), 1]] },
      });
      return newState;
    case actionTypes.INCOMPLETE:
      newState = update(state, {
        loading: { $splice: [[state.loading.indexOf(action.payload), 1]] },
      });
      return newState;
    case actionTypes.LOADING:
      newState = update(state, {
        loading: { $push: [action.payload] },
      });
      return newState;
    case actionTypes.TOGGLE_MENU:
      newState = update(state, {
        isMenuOpen: { $set: !state.isMenuOpen },
      });
      return newState;
    case actionTypes.ADD_FLASH_MESSAGE:
      newState = update(state, {
        flash_messages: { $push: [action.payload] },
      });
      return newState;
    case actionTypes.REMOVE_FLASH_MESSAGE:
      newState = update(state, {
        flash_messages: {
          $apply: function (messages) {
            return _.reject(messages, function (message) {
              return message.id === action.payload;
            });
          },
        },
      });
      return newState;
    case actionTypes.ADD_ERROR_MESSAGE:
      if (state.error_messages.length < 1) {
        newState = update(state, {
          error_messages: { $push: [action.payload] },
        });
      }

      return newState;
    case actionTypes.CLEAR_ERROR_MESSAGES:
      newState = update(state, {
        error_messages: { $set: [] },
      });
      return newState;
    default:
      return state;
  }
};
