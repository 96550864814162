import React, { Component } from "react";
import InfoBox from "../shared/InfoBox";
import { DOMAIN_BASE } from "../../redux/settings";

export default class Homeless extends Component {
  render() {
    const msg =
      "You've landed on a page with no domain associated. That probably means that you clicked on a link or entered a URL that's incomplete.";
    return (
      <div>
        <div
          className="d-flex flex-column p-5 align-items-center justify-content-around"
          style={{ height: "60vh" }}
        >
          <InfoBox msg={msg} title="Please enter your domain" />
          <div>
            <div>
              If your company name is Widgets, you probably want to go to:
            </div>
            <pre>https://widgets.{DOMAIN_BASE}/</pre>
          </div>
        </div>
      </div>
    );
  }
}
