import { initialState } from "../initial_state";
import * as actionTypes from "../action-types";

export default (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.LOADING:
      if (action.payload === actionTypes.LOAD_PENDING_CONCUR_TRIPS) {
        newState = {
          ...newState,
          pending_concur_trips: [],
        };
      }
      return newState;
    case actionTypes.LOAD_CONCUR_CONNECTED:
      newState = {
        ...newState,
        concur_connected: action.payload.concur_connected,
      };
      return newState;
    case actionTypes.LOAD_PENDING_CONCUR_TRIPS:
      newState = {
        ...newState,
        pending_concur_trips: action.payload.pending_concur_trips,
      };
      return newState;
    case actionTypes.LOAD_CONCUR_TRIP_DETAILS:
      newState = {
        ...newState,
        concur_trip: action.payload.concur_trip,
      };
      return newState;
    case actionTypes.CHECK_TRIP_CONFLICTS:
      newState = {
        ...newState,
        conflicts: action.payload.trip_conflicts,
      };
      return newState;
    default:
      return state;
  }
};
