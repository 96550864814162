import { initialState } from "../initial_state";
import update from "immutability-helper";
import * as actionTypes from "../action-types";

export default (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.DOMAIN_ADMIN_LOAD_CUSTOMIZATION:
      newState = update(state, {
        customization: { $set: action.payload },
      });
      return newState;
    case actionTypes.LOAD_OUTCOMES_RULES:
      // Converts arrays of email strings to arrays of hashes
      // with value and label keys required by CreatablePicker.
      // Would be better to find a way to do this at the display
      // level but wasn't able to reliably without bypassing Redux
      // altogether.
      newState = update(state, {
        outcomes_rules: {
          $set: action.payload.map((rule) => {
            if (Array.isArray(rule.tos)) {
              rule.tos = rule.tos.map((email) => {
                return { value: email, label: email };
              });
            }
            if (Array.isArray(rule.ccs)) {
              rule.ccs = rule.ccs.map((email) => {
                return { value: email, label: email };
              });
            }
            if (Array.isArray(rule.bccs)) {
              rule.bccs = rule.bccs.map((email) => {
                return { value: email, label: email };
              });
            }
            return rule;
          }),
        },
      });
      return newState;
    default:
      return state;
  }
};
