import React, { Component } from "react";
import WidgetBox from "../shared/WidgetBox";
import moment from "moment";
import { connect } from "react-redux";
import SunburstChart from "./SunburstChart";
import Status from "./SunburstStatus";

class SunburstReport extends Component {
  constructor(props) {
    super(props);
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = moment(new Date(y, m - 1, 1));
    const lastDay = moment(new Date(y, m, 0));
    this.state = {
      viewing: [],
      renderChart: true,
      dataLoaded: false,
      data: null,
      periodString: "Last Month",
      startDate: firstDay,
      endDate: lastDay,
      domain_id: props.user.domain_id,
    };
  }

  handleDateChange = (periodString, startDate, endDate) => {
    this.setState({ renderChart: true });
    this.setState({ data: null });
    this.setState({ dataLoaded: false });
    this.setState({ periodString });
    startDate && this.setState({ startDate });
    endDate && this.setState({ endDate });
  };

  onSelect = (node) => {
    let traverse = node;
    let viewingPath = [{ name: node.data.name, value: node.value }];
    while (traverse.parent) {
      viewingPath.unshift({
        name: traverse.parent.data.name,
        value: traverse.parent.value,
      });
      traverse = traverse.parent;
    }
    this.setState({ renderChart: false });
    this.setState({ viewing: viewingPath });
  };

  onCompleted = (data) => {
    const filtered = data.filter((item) => {
      return item.assessments.length > 0;
    });
    let chartData = {};
    chartData.name = "All Assessments";
    const allSize = filtered.reduce((a, v) => a + v.assessments.length, 0);
    chartData.children = [];
    filtered.forEach((country) => {
      const consults = country.assessments.filter((ass) => {
        return ass.result === "consult_required";
      });
      const noConsults = country.assessments.filter((ass) => {
        return ass.result !== "consult_required";
      });

      let consultStatii = {};
      consults.forEach((item) => {
        if (item.status in consultStatii) {
          consultStatii[item.status].count++;
        } else {
          consultStatii[item.status] = {};
          consultStatii[item.status].count = 1;
          consultStatii[item.status].status_string = item.status_string;
        }
      });

      let noConsultStatii = {};
      noConsults.forEach((item) => {
        if (item.status in noConsultStatii) {
          noConsultStatii[item.status].count++;
        } else {
          noConsultStatii[item.status] = {};
          noConsultStatii[item.status].count = 1;
          noConsultStatii[item.status].status_string = item.status_string;
        }
      });

      const consultChildren = Object.keys(consultStatii).map((status) => {
        return {
          name: `${consultStatii[status].status_string}`,
          size: consultStatii[status].count,
        };
      });

      const noConsultChildren = Object.keys(noConsultStatii).map((status) => {
        return {
          name: `${noConsultStatii[status].status_string}`,
          size: noConsultStatii[status].count,
        };
      });

      chartData.children.push({
        name: country.name,
        children: [
          {
            name: `Consult`,
            children: consultChildren,
          },
          {
            name: `No Consult`,
            children: noConsultChildren,
          },
        ],
      });
    });
    this.setState({
      data: chartData,
      dataLoaded: true,
      viewing: [{ name: "All Assessments", value: allSize }],
    });
  };

  render() {
    if (this.props.countries) {
      return (
        <WidgetBox label="Destination Outcome Breakdown">
          <div className="d-flex showChart">
            Show chart for:
            <div
              className="flex-auto mx-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.handleDateChange(
                  "This Month",
                  moment().startOf("month"),
                  moment().endOf("month")
                )
              }
            >
              This Month
            </div>
            <div> | </div>
            <div
              className="flex-auto mx-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.handleDateChange(
                  "Last Month",
                  moment().startOf("month").add(-1, "month"),
                  moment().endOf("month").add(-1, "month")
                )
              }
            >
              Last Month
            </div>
            <div> | </div>
            <div
              className="flex-auto mx-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.handleDateChange(
                  "This Year",
                  moment().startOf("year"),
                  moment().endOf("year")
                )
              }
            >
              This Year
            </div>
            <div> | </div>
            <div
              className="flex-auto mx-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.handleDateChange(
                  "Last Year",
                  moment().startOf("year").add(-1, "year"),
                  moment().endOf("year").add(-1, "year")
                )
              }
            >
              Last Year
            </div>
          </div>
          <div className="d-flex" style={{ flexWrap: "wrap-reverse" }}>
            <div>
              <SunburstChart
                shouldRender={this.state.renderChart}
                onCompleted={this.onCompleted}
                onSelect={this.onSelect}
                chartData={this.state.data}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                //onSelect={this.onSelect}
                scale="linear" // or exponential
                tooltipContent={
                  <div
                    class="sunburstTooltip"
                    style="position:absolute; color:'black'; z-index:10; background: #e2e2e2; padding: 2px; text-align: center;"
                  />
                } // eslint-disable-line
                tooltip
                tooltipPosition="right"
                keyId="anagraph"
                width="480"
                height="400"
              />
            </div>
            {this.state.dataLoaded && (
              <Status
                periodString={this.state.periodString}
                viewing={this.state.viewing}
              />
            )}
          </div>
        </WidgetBox>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    countries: state.countries,
    user: state.user,
  };
};

const connectedComponent = connect(mapStateToProps)(SunburstReport);

export default connectedComponent;
