import React, { Component } from "react";
import Rollbar from "rollbar";
import ErrorPage from "./ErrorPage";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.rollbar = new Rollbar({
      accessToken: "297d48e2d8a1401095681f1172fe50d2",
      payload: {
        environment: process.env.NODE_ENV,
      },
    });
  }

  componentDidCatch(error, info) {
    console.log("ERROR: ");
    console.log(error);
    console.log(info);
    console.log("Environment");
    console.log(process.env.NODE_ENV);
    this.setState({ hasError: true });
    // temporarily disable environment check
    // if (process.env.NODE_ENV === 'production') {
    this.rollbar.error(error, info);
    // }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}
