import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationStack } from "react-notification";
import { removeFlashMessage } from "../../redux/actions/ui_actions";
import { dispatchResults } from "../../redux/actions/app_actions";

class Notifications extends Component {
  render() {
    return (
      <div>
        <NotificationStack
          notifications={this.props.messages}
          onDismiss={(i) => {
            dispatchResults(removeFlashMessage(i));
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.ui.flash_messages,
  };
};

export default connect(mapStateToProps, {
  removeFlashMessage,
})(Notifications);
