import { initialState } from "../initial_state";
import update from "immutability-helper";
import * as actionTypes from "../action-types";

export default (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.LOAD_DOMAIN_ADMIN_SETTINGS:
      const setting = action.payload;
      if (Array.isArray(setting.departments)) {
        setting.departments = setting.departments.map((department) => {
          return { value: department, label: department };
        });
      }
      if (Array.isArray(setting.divisions)) {
        setting.divisions = setting.divisions.map((division) => {
          return { value: division, label: division };
        });
      }
      newState = update(state, {
        $set: setting,
      });
      return newState;
    default:
      return state;
  }
};
