import React from "react";
import UserNav from "./user_nav";
import GuestNav from "./guest_nav";
import Auth from "../../auth/Auth";

const auth = new Auth();

function TopRightNav(props) {
  const isLoggedIn = auth.isAuthenticated();
  if (isLoggedIn) {
    return <UserNav />;
  }
  return <GuestNav />;
}

export default TopRightNav;
