import React from "react";
import { RotatingPlane } from "better-react-spinkit";

const PERMISO_DARK_BLUE = "#083d5d";

export const BigLoadingThing = (props) => (
  <div
    className="d-flex align-content-around justify-content-around w-100"
    style={{ height: "50vh" }}
  >
    <div className="my-auto">
      <RotatingPlane size={50} color={PERMISO_DARK_BLUE} />
    </div>
  </div>
);

export const SmallLoadingThing = (props) => (
  <RotatingPlane size={10} color={PERMISO_DARK_BLUE} className="ml-2" />
);
