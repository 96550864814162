export const initialState = {
  user: null,
  announcements: null,
  domain: null,
  domain_announcements: null,
  domain_admin_settings: null,
  domain_admin_announcements: null,
  countries: null,
  applications: { all: null, activities: null },
  activities: null,
  screening_questions: null,
  uploads: null,
  unavailable_country_messages: null,
  permiso_admin_pages: null,
  permiso_admin_news: null,
  permiso_admin_country: null,
  country_page_additions: null,
  permiso_admin_special_groups: null,
  permiso_admin_announcements: null,
  permiso_admin_outcome_rules: null,
  substitutions: null,
  pages: null,
  report_data: null,
  report_download: null,
  rules: {
    outcomes_rules: null,
    customization: null,
  },
  ui: {
    isMenuOpen: false,
    loading: [],
    flash_messages: [],
    error_messages: [],
  },
  concur: null,
};
