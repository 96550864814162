import React, { Component } from "react";
import { ROOT_URL } from "../../redux/settings";
import axiosApi from "../../redux/axiosApi";
import alertApiError from "../../helpers/alert_api_error";
import { BigLoadingThing } from "../shared/LoadingThing";
import CountryPageTemplate from "./templates/CountryPageTemplate";

const templatesByPageType = {
  CountryPage: CountryPageTemplate,
};

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      type: null,
      loading: false,
      load: true,
      page: null,
    };
  }

  componentDidMount() {
    this.loadPage();
  }

  componentDidUpdate() {
    this.loadPage();
  }

  static getDerivedStateFromProps(newProps, oldState) {
    if (newProps.match.params.id !== oldState.id) {
      return {
        id: newProps.match.params.id,
        type: newProps.type,
        load: true,
        loading: false,
      };
    } else {
      return null;
    }
  }

  loadPage = () => {
    if (this.state.load && this.state.id && !this.state.loading) {
      let url;
      if (this.state.type === "CountryPage") {
        url = `${ROOT_URL}/wiki/country_pages/${this.state.id}`;
      } else {
        url = `${ROOT_URL}/wiki/pages/${this.state.id}`;
      }

      let newsUrl = "https://pearl.api.permiso.net/api/v1/public/news";
      if (url.includes("staging")) {
        newsUrl = newsUrl.replace(".api.", ".staging-api.");
      }

      this.setState({ loading: true, load: false });
      axiosApi
        .get(url)
        .then((result) => {
          this.setState({ page: result.data, loading: false });
          axiosApi
            .get(newsUrl)
            .then((newsResult) => {
              let news = newsResult.data.filter(
                (news) =>
                  ((news.tags || "")[0] || "").toLowerCase() ===
                  (result.data.data.country_name || "").toLowerCase()
              );
              news.sort((a, b) =>
                new Date(a.posted) > new Date(b.posted) ? -1 : 1
              );
              news = news.slice(0, 10).map((news) => {
                return {
                  title: news.title,
                  content:
                    "<h6>" +
                    new Date(news.posted).toLocaleString() +
                    "</h6><div>" +
                    news.content +
                    "</div>",
                };
              });

              if (news.length) {
                const newsData = {};
                news.forEach((ni) => {
                  newsData[`news.${ni.title}`] = ni.content;
                });
                this.setState({
                  page: {
                    ...result.data,
                    data: {
                      ...result.data.data,
                      ...newsData,
                    },
                  },
                });
              }
            })
            .catch(alertApiError);
        })
        .catch((error) => {
          this.setState({ loading: false, load: true });
          console.log(error);
          alertApiError(error);
        });
    }
  };

  renderHTML = (val) => {
    return { __html: val };
  };

  render() {
    const { page } = this.state;

    const Template = templatesByPageType[this.state.type];
    if (Template && page && page.data) {
      return <Template data={{ ...page.data, url: page.url }} />;
    }

    const content =
      page && page.rendered_disp_content
        ? page.rendered_disp_content.replace(
            /src="\/images/g,
            'src="https://permiso-rails.therian.co/images'
          )
        : "No content found";

    if (page) {
      return (
        <div>
          <h2 className="text-left">{page.header}</h2>
          <div
            className="text-left"
            dangerouslySetInnerHTML={this.renderHTML(content)}
          />
        </div>
      );
    } else {
      return <BigLoadingThing />;
    }
  }
}

export default Page;
