import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import ErrorBoundary from "./components/ErrorBoundary";
import store from "./redux/store";
import "./polyfill";
import registerServiceWorker from "./registerServiceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { datadogRum } from "@datadog/browser-rum";

if (window.location.hostname.includes("pearlhub.io")) {
  datadogRum.init({
    applicationId: "fce72bde-ca00-49fb-bc00-842a7b6d2813",
    clientToken: "pubbcd355202e584b684dfe2f0ea9d1e4f5",
    site: "datadoghq.com",
    service: "pearlhub-react",
    version: "1.0.0",
    sampleRate: 100,
    trackInteractions: true,
  });

  Sentry.init({
    dsn: "https://4d367953f29b47929ac1916f00b77b00@o572460.ingest.sentry.io/5721877",
    environment: window.location.hostname.includes("staging")
      ? "staging"
      : "production",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <App store={store} />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
