import React from "react";
import Breadcrumbs from "../../shared/Breadcrumbs";
import AccordionNavPage from "../../shared/AccordionNavPage";

import { getAssetURL } from "../../../redux/settings";

const sections = [
  {
    // label: 'Full Summary', // TODO: look at this
    // sections: [
    //   {
    //     label: 'Show All',
    //     keys:['fs_all']
    //   }
    // ],
    label: "Business Visitors",
    sections: [
      {
        label: "Overview",
        keys: ["bv_overview"],
      },
      {
        label: "Allowable Activities",
        keys: ["bv_allowable_activities"],
      },
      {
        label: "Basic Requirements",
        keys: ["bv_basic_requirements_introduction", "bv_basic_requirements"],
      },
      {
        label: "Maximum Period of Stay",
        keys: ["bv_maximum_period_of_stay"],
      },
      {
        label: "Application Timeline",
        keys: ["bv_application_timeline"],
      },
    ],
  },
  {
    label: "COVID-19 Rules",
    keys: ["covid19_internal"],
  },
  {
    label: "Work Authorization",
    sections: [
      {
        label: "Work Permit Types",
        keys: ["wa_work_permit_types_internal"],
      },
      {
        label: "Work Permit Process",
        keys: ["wa_work_permit_process"],
      },
      {
        label: "Maximum Period of Stay",
        keys: ["wa_maximum_period_of_stay_internal"],
      },
      {
        label: "Application Timeline",
        keys: ["wa_application_timeline"],
      },
      {
        label: "Dependents",
        keys: ["wa_dependents"],
      },
    ],
  },
  {
    label: "Red Flags",
    keys: ["red_flags"],
  },
  {
    label: "Other Information",
    sections: [
      {
        label: "Employment Eligibility Verification",
        keys: ["oi_employment_eligibility_verification"],
      },
      {
        label: "Trusted Traveler Options",
        keys: ["oi_trusted_traveler_options"],
      },
      {
        label: "Helpful Links",
        keys: ["oi_helpful_links"],
      },
      {
        label: "Commonly Requested Documents",
        keys: ["oi_commonly_requested_documents"],
      },
      {
        label: "Global Immigration FAQs",
        keys: ["oi_global_immigration_faqs"],
      },
      {
        label: "Dos and Don'ts",
        keys: ["oi_dos_and_donts"],
      },
    ],
  },
  {
    label: "News",
    sections: [],
  },
];

export default class CountryPageTemplate extends React.Component {
  constructor() {
    super();
    this.state = {
      open: {},
    };
  }

  renderDataByKey(key) {
    const cleanContent = (c) => (c ? c.replace(/<br ?\/?>\n/g, "\n") : null);

    let content = this.props.data[key];
    const highlights = ((window.location.hash || "").split("#")[1] || "")
      .split(",")
      .filter((v) => v);

    if (Array.isArray(content)) {
      content = content.map((c) => {
        try {
          highlights.forEach((h) => {
            c = (c.value || c).replace(
              new RegExp(h, "gi"),
              (match) =>
                `<strong class="rounded-pill bg-primary text-light pr-2 pl-2">${match}</strong>`
            );
          });
        } catch (e) {
          // console.error(e);
        }
        return c;
      });

      content = (
        <ul style={{ paddingLeft: "1em" }}>
          {content
            .map((row) => (row && row.value) || row)
            .filter((v) => v && v.length)
            .map((v, i) => (
              <li
                key={i}
                dangerouslySetInnerHTML={{ __html: cleanContent(v) }}
              ></li>
            ))}
        </ul>
      );
    } else {
      highlights.forEach((h) => {
        content = content.replace(
          new RegExp(h, "gi"),
          (match) =>
            `<strong class="rounded-pill bg-primary text-light pr-2 pl-2">${match}</strong>`
        );
      });

      content = (
        <p
          className="country_summary_section"
          dangerouslySetInnerHTML={{
            __html: cleanContent(content),
          }}
        />
      );
    }

    return (
      <div key={key} style={{ color: "#666" }}>
        {content}
      </div>
    );
  }

  renderArrowByLabel(label) {
    return (
      <div
        style={{
          color: "#666",
          display: "inline-block",
          fontSize: "0.8em",
          fontWeight: 300,
          marginRight: "0.5em",
        }}
      >
        <i
          className={`fa fa-chevron-${this.state.open[label] ? "up" : "down"}`}
        />
      </div>
    );
  }

  toggleOpen(label) {
    this.setState({
      open: { ...this.state.open, [label]: !this.state.open[label] },
    });
  }

  render() {
    const { data } = this.props;

    let newSections = sections.slice();
    newSections = newSections.map((s) => {
      if (s.label === "News" && this.props.data) {
        const keys = Object.keys(this.props.data).filter(
          (k) => k && k.startsWith("news.")
        );
        s.sections = keys.map((k) => ({
          label: k.slice(5),
          keys: [k],
        }));
      }
      return s;
    });

    const checkSectionEmpty = (section) => {
      if (section.keys) {
        if (
          section.keys
            .map((sectionKey) => Boolean(this.props.data[sectionKey]))
            .some((hasValue) => hasValue === true)
        ) {
          return false;
        }
      }
      if (section.sections) {
        // check subsections
        return section.sections.every((it) => {
          return checkSectionEmpty(it);
        });
      }
      return true;
    };

    if (!data) {
      return null;
    }

    const flagUrl = data.url && `${getAssetURL()}/images/flags/${data.url}.png`;

    let documentSection = null;
    if (data.document_links && data.document_links.length) {
      documentSection = { label: "Documents", links: [], sections: [] };

      let subSection = null;
      for (let i = 0; i < data.document_links.length; i += 1) {
        const l = data.document_links[i];

        if (!l.value && l.label) {
          if (subSection && subSection.links.length) {
            documentSection.sections.push(subSection);
          }

          subSection = { label: l.label, links: [] };
        } else if (l.value) {
          if (subSection) {
            subSection.links.push([l.label || l.value, l.value]);
          } else {
            documentSection.links.push([l.label || l.value, l.value]);
          }
        }
      }
    }

    const highlights = ((window.location.hash || "").split("#")[1] || "")
      .split(",")
      .filter((v) => v);
    const flipOpen = highlights.length > 0;

    // const parentPath = `/wiki/country_pages#${(
    //   data.region || ""
    // ).toLowerCase()}`;

    return (
      <div>
        <Breadcrumbs
          extras={{
            [window.location.pathname]: {
              label: data.country_name,
              parent: "/wiki/country_pages",
            },
          }}
        />

        <div style={{ margin: "auto", maxWidth: 800, textAlign: "left" }}>
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h1>{data.country_name}</h1>
              <h5>{data.region}</h5>
              <br />
              <p
                style={{ color: "#666" }}
                dangerouslySetInnerHTML={{
                  __html: data.immigration_at_a_glance_internal,
                }}
              />
            </div>
            {flagUrl && (
              <div>
                <img src={flagUrl} style={{ height: 70 }} alt="" />
                <br />
                <br />
              </div>
            )}
          </div>
          <br />
          <hr />

          {newSections.map((section) =>
            checkSectionEmpty(section) ? (
              ""
            ) : (
              <div key={section.label}>
                <h2
                  onClick={this.toggleOpen.bind(this, section.label)}
                  style={{ cursor: "pointer", fontWeight: 300 }}
                >
                  {this.renderArrowByLabel(section.label)}
                  {section.label}
                </h2>

                <div
                  style={{
                    backgroundColor: "#f7f7f7",
                    display: (
                      flipOpen
                        ? !this.state.open[section.label]
                        : this.state.open[section.label]
                    )
                      ? "block"
                      : "none",
                    paddingLeft: "3.2em",
                    paddingRight: "1em",
                  }}
                >
                  <br />

                  {section.sections
                    ? section.sections.map((subSection, subIdx) =>
                        checkSectionEmpty(subSection) ? (
                          ""
                        ) : (
                          <div key={subSection.label}>
                            <h4
                              onClick={this.toggleOpen.bind(
                                this,
                                subSection.label
                              )}
                              style={{
                                cursor: "pointer",
                                color:
                                  section.label === "News"
                                    ? "#007bff"
                                    : "black",
                              }}
                            >
                              {this.renderArrowByLabel(subSection.label)}
                              {subSection.label}
                            </h4>

                            <div
                              style={{
                                display: (
                                  flipOpen
                                    ? !this.state.open[subSection.label]
                                    : this.state.open[subSection.label]
                                )
                                  ? "block"
                                  : "none",
                                paddingLeft: "2em",
                              }}
                            >
                              <br />
                              {subSection.keys
                                ? subSection.keys.map(
                                    this.renderDataByKey.bind(this)
                                  )
                                : null}
                            </div>

                            {subIdx < section.sections.length - 1 ? (
                              <hr />
                            ) : (
                              <br />
                            )}
                          </div>
                        )
                      )
                    : null}
                  {section.keys
                    ? section.keys.map(this.renderDataByKey.bind(this))
                    : null}

                  <br />
                </div>

                <hr />
              </div>
            )
          )}

          {documentSection &&
          (documentSection.links.length || documentSection.sections.length) ? (
            <div style={{ marginTop: -40 }}>
              <AccordionNavPage sections={[documentSection]} />
            </div>
          ) : null}

          <div>
            <br />
            <br />
            <hr />
            <a
              href="/page/global-dos-donts"
              target="_blank"
              rel="noopener noreferrer"
            >
              Do's and Don'ts While You Have a Pending Application
            </a>
            <br />
            <a
              href="/page/global-commonly-requested-docs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Commonly Requested Documents for Work Permit Applications
            </a>
            <br />
            <a
              href="/page/global-immigration-faqs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Global Immigration FAQ's
            </a>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}
