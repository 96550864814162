import auth0 from "auth0-js";
import history from "../history";
import { SUBDOMAIN, bypassLogin } from "../../redux/settings";

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: "permiso.auth0.com",
    clientID: "qjuxjUxqtu3y4JR28ENVDrwz8WP70zwP",
    redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
    responseType: "token id_token",
    audience: "permiso-api",
    scope: "profile openid email",
  });

  login = () => {
    localStorage.setItem("visit_url", window.location.pathname);
    this.auth0.authorize();
  };

  redirect = () => {
    const visitUrl = localStorage.getItem("visit_url");
    if (SUBDOMAIN === "" || SUBDOMAIN === null) {
      window.location.replace("/homeless");
    } else {
      if (visitUrl) {
        localStorage.removeItem("visit_url");
        window.location.replace(visitUrl);
      } else {
        window.location.replace("/home");
      }
    }
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.redirect();
      } else if (err) {
        window.location.replace("/home");
        console.log(err);
      }
    });
  };

  setSession = (authResult) => {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    // navigate to the home route
    history.replace("/home");
  };

  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("agent_id");
    // navigate to the home route
    window.location.replace("/home");
  };

  isAuthenticated = () => {
    // Bypass Auth0 JWT check for development
    if (bypassLogin()) {
      return true;
    }

    // Check whether the current time is past the
    // access token's expiry time
    // eslint-disable-next-line
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };
}
