import { dispatchResults } from "./app_actions";
import axiosApi from "../axiosApi";
import alertApiError from "../../helpers/alert_api_error";
import _ from "lodash";
import { ROOT_URL } from "../settings";
import * as actionTypes from "../action-types";

export function loadCountries(callback = () => {}) {
  const url = `${ROOT_URL}/countries`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_COUNTRIES,
        payload: _.keyBy(
          result.data.map((d) => {
            const extra = {};
            try {
              Object.entries(d.partner_credits).forEach(([k, v]) => {
                extra[`partner_credits_${k}`] = v;
              });
            } catch (e) {
              // ignore this
            }

            return {
              ...d,
              ...extra,
            };
          }),
          (c) => {
            return c.id;
          }
        ),
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_COUNTRIES,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_COUNTRIES,
      });
      console.log(error);
      alertApiError(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_COUNTRIES };
}
