import React, { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { setLoading, setComplete } from "../../redux/actions/ui_actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import CirclePack from "./CirclePack";

class CirclePackChart extends Component {
  shouldComponentUpdate(nextProps) {
    if (!nextProps.data.loading && !nextProps.chartData) {
      nextProps.onCompleted(nextProps.data.countries);
    }
    return nextProps.shouldRender;
  }

  render() {
    if (this.props.data.loading) {
      return (
        <div
          className="d-flex flex-column justify-content-around"
          style={{
            height: "500px",
            width: "500px",
          }}
        >
          <div>
            Loading... Please wait... this may take a few minutes for long
            periods.
          </div>
        </div>
      );
    } else {
      if (!this.props.chartData) {
        return <div>Loading... Processing data.</div>;
      } else {
        return <CirclePack {...this.props} data={this.props.chartData} />;
      }
    }
  }
}

const ReportQuery = gql`
  query ReportQuery($start_date: String, $end_date: String) {
    countries {
      name
      assessments(start_date: $start_date, end_date: $end_date) {
        result
        result_string
        status
        status_string
        details {
          activities {
            name
          }
        }
      }
    }
  }
`;

export default compose(
  graphql(ReportQuery, {
    options(ownProps) {
      return {
        variables: {
          domain: ownProps.domainId,
          start_date: ownProps.startDate.format("YYYY-MM-DD") || "",
          end_date: ownProps.endDate.format("YYYY-MM-DD") || "",
        },
        errorPolicy: "all",
      };
    },
  })
)(connect(null, { setLoading, setComplete })(CirclePackChart));
