import React from "react";
import NavList from "./NavList";
import NavTiles from "./NavTiles";
import getRootNavigationTiles from "./NavigationConfig";

const getTilesForPathRecursive = (tiles, path) => {
  if (path === "/home" || path === "/") return tiles;
  let result = tiles.find((t) => t.link === path && t.children);
  if (result && result.children) return result.children;
  result = tiles
    .flatMap((t) => {
      if (t.children) {
        let childResult = getTilesForPathRecursive(t.children, path);
        if (childResult) return [childResult];
      }
      return [];
    })
    .filter((r) => r.length > 0);
  if (result.length > 0) return result[0];
  return [];
};

function NavPage({ domain, user }) {
  const das = domain.domain_admin_setting.dashboard_tiles_visibility;
  const {
    permiso_admin_setting: { feature },
  } = domain;

  const filterNavTiles = (tile) => {
    switch (tile.id) {
      case "news":
        return das && das.news !== false;
      case "permiso":
        return (
          das && das.business_travel !== false && feature.travel_assessments
        );
      case "doc_vault":
        return das && das.doc_vault !== false;
      case "rec":
        return das && das.recruiter_tools !== false;
      case "i9":
        return das && das.i9 !== false;
      case "resources":
        return das && das.help !== false;
      case "report_custom":
        return ["admin", "dev"].includes(user.role);
      case "report_usage":
        return user.role === "dev";
      case "report_tax":
        return feature.tax_assessments;
      case "us":
        return das && das.us !== false;
      case "amer":
        return das && das.amer !== false;
      case "emea":
        return das && das.emea !== false;
      case "apac":
        return das && das.apac !== false;
      case "traveler_links":
        return das && das.traveler_links !== false;
      case "immigration_cases":
        return das && das.immigration_cases !== false;
      case "team_resources":
        return das && das.team_resources !== false;
      case "profile":
        return false;
      default:
        return true;
    }
  };

  const tiles = getTilesForPathRecursive(
    getRootNavigationTiles(filterNavTiles),
    window.location.pathname
  );

  if (tiles.some((tile) => tile.navType === "list")) {
    return <NavList tiles={tiles} />;
  }

  return <NavTiles tiles={tiles} />;
}

export default NavPage;
