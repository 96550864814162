import React, { Component } from "react";
import { ROOT_URL } from "../../redux/settings";
import axiosApi from "../../redux/axiosApi";
import alertApiError from "../../helpers/alert_api_error";
import { getAssetURL } from "../../redux/settings";
import { withRouter } from "react-router-dom";
import { BigLoadingThing } from "../shared/LoadingThing";

class CountryPageIndex extends Component {
  constructor(props) {
    super(props);
    this.state = { pages: null };
  }

  componentDidMount() {
    this.state.pages || this.loadPages(this.props.match.params.id);
  }

  loadPages = (id) => {
    const url = `${ROOT_URL}/public/countries`;
    axiosApi
      .get(url)
      .then((result) => {
        this.setState({ pages: result.data });
      })
      .catch((error) => {
        console.log(error);
        alertApiError(error);
      });
  };

  CountryPage = ({ page }) => {
    const itemStyle = {
      flex: "0 0 20%",
      minHeight: "150px",
    };
    const imageStyle = {
      cursor: "pointer",
      maxWidth: "120px",
      padding: 0,
    };

    const showCountry = (page) => {
      this.props.history.push(`/country_page/${page.url_slug}`);
    };

    const flagUrl =
      page.url_slug && `${getAssetURL()}/images/flags/${page.url_slug}.png`;

    return (
      <div
        className="d-flex flex-column flex-wrap align-items-center"
        style={itemStyle}
        onClick={() => showCountry(page)}
      >
        <div>
          <img alt="flag" src={flagUrl} style={imageStyle} />
        </div>
        <div>{page.country_name}</div>
      </div>
    );
  };

  render() {
    let { pages } = this.state;
    const region = `${window.location.hash || ""}`.slice(1);

    if (pages) {
      pages = pages.filter((p) => {
        return (
          p.country_name &&
          (!region.length || (p.region && p.region.toLowerCase() === region))
        );
      });
      pages.sort((a, b) => (a.country_name > b.country_name ? 1 : -1), []);

      return (
        <div className="country-page-index">
          {region.length ? <h1>{region.toUpperCase()} Immigration</h1> : null}
          <br />
          <br />
          <div className="d-flex flex-row flex-wrap immigration-flags">
            {pages.map((page) => {
              return <this.CountryPage page={page} key={page.url_slug} />;
            })}
          </div>
        </div>
      );
    } else {
      return <BigLoadingThing />;
    }
  }
}

export default withRouter(CountryPageIndex);
