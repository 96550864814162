import React from "react";

import AccordionNavPage from "../shared/AccordionNavPage";

export const sections = [
  {
    label: "Work Permits & Visas",
    sections: [
      {
        label: "General Visa Information",
        links: [
          ["Overview", "/page/visa-application-process-video"],
          [
            "What You Need to Know Before Traveling Internationally",
            "/page/travel-faqs-visa-stamping",
          ],
          [
            "Visa Issuance & Arrival Information",
            "/page/visa-issuance-arrival",
          ],
          [
            "General Checklist for Foreign Employees",
            "/page/general-checklist-for-foreign-employees",
          ],
          [
            "DS-160 Nonimmigration Visa Application FAQs",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/DS-160+Nonimmigrant+Visa+Application+FAQs+(1).pdf",
          ],
        ],
      },
      {
        label: "Most Common U.S. Work Visas",
        links: [
          ["H-1B Visas Overview", "/page/h1b-visas-overview"],
          [
            "H-1B Transfer Process Roadmap",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/H-1B+Transfer+Process+Roadmap.pdf",
          ],
          ["H-1B Transfer FAQs for Candidates", "/page/h-1b-transfer-faqs"],
          ["H-1B Transfer Overview", "/page/h1b-visa-video"],
          [
            "H-1B Petition Document Checklist",
            "/page/h1b-petition-document-checklist",
          ],
          ["What is the H-1B Cap?", "/page/what-is-the-h1b-cap"],
          [
            "Things to Remember about FDNS Site Visits",
            "/page/fraud-detection",
          ],
          [
            "H-1B to Greencard Process Flow",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/H-1B+to+Green+Card+Process+Flow+Diagram.pdf",
          ],
          [
            "F-1/OPT(Student/Optional Practical Training with EAD) Visas Overview",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/F-1+OPT+Visa+Overview+.pdf",
          ],
          [
            "J-1 (Trainee/Cultural Exchange Visas Overview)",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/J-1+Visa+Overview+.pdf",
          ],
          ["L-1 Intracompany Transferee Visas Overview", "/page/l-1-visa"],
          [
            "How Do I Schedule My Blanket L-1 Visa Appointment",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/How+Do+I+Schedule+My+Blanket+L-1+Visa+Appointment+.pdf",
          ],
          [
            "Sample L-1 Documents",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/Sample+L-1+Documents+.pdf",
          ],
          ["O-1 (Extraordinary Ability)", "/page/o-1-visa"],
        ],
      },
      {
        label: "Work Visas for Nationals of Specific Countries",
        links: [
          [
            "E-3 (Australia) Consular Process Roadmap",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/E-3+Consular+Process+Roadmap.pdf",
          ],
          [
            "H-1B1 (Chile & Singapore) Process Roadmap",
            "https://permiso-linked-assets.s3-us-west-1.amazonaws.com/H-1B1+Process+Roadmap.pdf",
          ],
          ["TN (Canada & Mexico) Visas Overview", "/page/tn-visas-overview"],
        ],
      },
    ],
  },
  {
    label: "Permanent Residency (Green Card)",
    sections: [
      {
        label: "PERM-based Permanent Residence Applications",
        links: [
          // [
          //   '3 Phase Process Overview',
          //   'https://permiso-linked-assets.s3-us-west-1.amazonaws.com/PERM+Phase+1-3.pdf',
          // ],
          ["Green Card Overview", "/page/green-card-overview-video"],
          // [
          //   'PERM Calendar',
          //   'https://permiso-linked-assets.s3-us-west-1.amazonaws.com/Perm+Calendar.pdf',
          // ],
          [
            "Top 10 Things to Remember About the PERM Process",
            "/page/remember-about-perm-process",
          ],
          [
            "PERM Employment Verification Letter FAQ",
            "/page/employment-verification-letters",
          ],
          [
            "EB-2 and EB-3 Preference Category Overview",
            "/eb2-and-eb3-preference-overview",
          ],
        ],
      },
      {
        label: "Adjustment of Status (I-485) Details",
        links: [
          ["Translations", "/page/aos-memo-translations"],
          [
            "Vaccination Requirements",
            "/page/aos-memo-vaccination-requirements",
          ],
          ["Travel Restrictions", "/page/aos-memo-travel-restrictions"],
          ["AOS Documents Checklist", "/page/aos-document-checklist"],
        ],
      },
      {
        label: "EB-1 First Preference Category Application",
        links: [["EB-1 Extraordinary Ability Overview", "/page/eb1"]],
      },
      {
        label: "General Information",
        links: [
          [
            "Maintaining Lawful Permanent Resident Status",
            "/page/maintaining-lawful-permanent-resident-status",
          ],
          [
            "Transfer a Pending Application to a New Employer",
            "/page/transfer-pending-aos",
          ],
        ],
      },
    ],
  },
  {
    label: "Important Links",
    links: [
      [
        "U.S. Government Links & Processing Times",
        "/page/govnt-links-processing-times",
      ],
      ["U.S. Forms & Instructions", "/page/us-immigration-links"],
    ],
  },
  {
    label: "Employment Eligibility Verification",
    links: [["I-9 & E-Verify", "https://sts.i9complete.com/"]],
  },
];

export default () => <AccordionNavPage sections={sections} />;
