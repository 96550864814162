import { dispatchResults, currentUser } from "./app_actions";
import axiosApi from "../axiosApi";
import { addFlashMessage } from "./ui_actions";
import alertApiError from "../../helpers/alert_api_error";
import { reset } from "redux-form";
import { ROOT_URL } from "../settings";
import * as actionTypes from "../action-types";

export function loadUser(callback = () => {}) {
  const url = `${ROOT_URL}/users/current`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults(loadAgentUser());
      dispatchResults({
        type: actionTypes.LOAD_USER,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_USER,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_USER,
      });
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_USER };
}

export function loadAgentUser() {
  const url = `${ROOT_URL}/users/current/agent`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_AGENT_USER,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_AGENT_USER,
      });
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_AGENT_USER,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_AGENT_USER };
}

export function loadAssessments(user_id, callback = () => {}) {
  const url = `${ROOT_URL}/users/${user_id}/assessments`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_ASSESSMENTS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_ASSESSMENTS,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_ASSESSMENTS,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_ASSESSMENTS };
}

export function loadUserApplications(user_id, callback = () => {}) {
  const url = `${ROOT_URL}/users/${user_id}/applications`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_USER_APPLICATIONS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_USER_APPLICATIONS,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_USER_APPLICATIONS,
      });
      alertApiError(error);
      console.log(error);
    });
  return {
    type: actionTypes.LOADING,
    payload: actionTypes.LOAD_USER_APPLICATIONS,
  };
}

export function updateUserApplication(id, payload, callback = () => {}) {
  const user_id = currentUser().id;
  const url = `${ROOT_URL}/users/${user_id}/applications/${id}`;
  axiosApi
    .patch(url, payload)
    .then((result) => {
      dispatchResults({
        type: actionTypes.UPDATE_USER_APPLICATION,
        payload: result.data,
      });
      dispatchResults(loadUserApplications(user_id));
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.UPDATE_USER_APPLICATION,
      });
      addFlashMessage(url, "success", "Assessment updated");
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.UPDATE_USER_APPLICATION,
      });
      alertApiError(error);
      console.log(error);
    });
  return {
    type: actionTypes.LOADING,
    payload: actionTypes.UPDATE_USER_APPLICATION,
  };
}

export function deleteUserApplication(id, callback = () => {}) {
  const user_id = currentUser().id;
  const url = `${ROOT_URL}/users/${user_id}/applications/${id}`;
  axiosApi
    .del(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.DELETE_USER_APPLICATION,
        payload: result.data,
      });
      dispatchResults(loadUserApplications(user_id));
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.DELETE_USER_APPLICATION,
      });
      addFlashMessage(url, "success", "Assessment removed");
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.DELETE_USER_APPLICATION,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.DELETE_ASSESSMENT };
}

export function deleteAssessment(id, callback = () => {}) {
  const user_id = currentUser().id;
  const url = `${ROOT_URL}/users/${user_id}/assessments/${id}`;
  axiosApi
    .del(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.DELETE_ASSESSMENT,
        payload: result.data,
      });
      dispatchResults(loadAssessments(user_id));
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.DELETE_ASSESSMENT,
      });
      addFlashMessage(url, "success", "Assessment removed");
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.DELETE_ASSESSMENT,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.DELETE_ASSESSMENT };
}

export function loadTrips(user_id, callback = () => {}) {
  const url = `${ROOT_URL}/users/${user_id}/trips`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_TRIPS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_TRIPS,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_TRIPS,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_TRIPS };
}

export function addPassport(
  user_id,
  values,
  callback = () => {
    return null;
  }
) {
  const url = `${ROOT_URL}/users/${user_id}/passports`;
  axiosApi
    .post(url, values)
    .then(() => {
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.ADD_PASSPORT,
      });
      dispatchResults(reset("new_passport"));
      dispatchResults(loadUser());
      addFlashMessage(url, "success", "Passport added");
      callback();
    })
    .catch((error) => {
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.ADD_PASSPORT };
}

export function updatePassport(
  user_id,
  values,
  callback = () => {
    return null;
  }
) {
  const url = `${ROOT_URL}/users/${user_id}/passports/${values.id}`;
  axiosApi
    .patch(url, values)
    .then(() => {
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.UPDATE_PASSPORT,
      });
      dispatchResults(loadUser());
      addFlashMessage(url, "success", "Passport updated");
      callback();
    })
    .catch((error) => {
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.UPDATE_PASSPORT };
}

export function deletePassport(
  passport,
  callback = () => {
    return null;
  }
) {
  const url = `${ROOT_URL}/users/${passport.user_id}/passports/${passport.id}`;
  axiosApi
    .del(url)
    .then(() => {
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.DELETE_PASSPORT,
      });
      dispatchResults(loadUser());
      addFlashMessage(url, "success", "Passport removed");
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.DELETE_PASSPORT,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: null };
}

export function loadAgents(user_id, callback = () => {}) {
  const url = `${ROOT_URL}/users/${user_id}/agents`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_AGENTS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_AGENTS,
      });
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_AGENTS,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_AGENTS };
}
export function updateAgents(
  user_id,
  agents,
  callback = () => {
    return null;
  }
) {
  const url = `${ROOT_URL}/users/${user_id}/agents`;
  axiosApi
    .patch(url, agents)
    .then((result) => dispatchResults(loadAgents(user_id)))
    .catch((error) => {
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: "Updating user agents" };
}

export function updateProfile(user_id, values, callback = () => {}) {
  const url = `${ROOT_URL}/users/${user_id}`;
  axiosApi
    .patch(url, values)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_USER,
        payload: result.data,
      });
      dispatchResults(loadAgentUser());
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.UPDATE_PROFILE,
      });
      addFlashMessage(url, "success", "Profile updated");
      callback();
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.UPDATE_PROFILE,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.UPDATE_PROFILE };
}

export function loadDomains() {
  const url = `${ROOT_URL}/domains/`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_DOMAINS,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_DOMAINS,
      });
    })
    .catch((error) => {
      dispatchResults({
        type: actionTypes.INCOMPLETE,
        payload: actionTypes.LOAD_DOMAINS,
      });
      alertApiError(error);
      console.log(error);
    });
  return { type: actionTypes.LOADING, payload: actionTypes.LOAD_DOMAINS };
}

export function loadDashboardTilesVisibility() {
  const url = `${ROOT_URL}/users/current/dashboard_tiles_visibility`;
  axiosApi
    .get(url)
    .then((result) => {
      dispatchResults({
        type: actionTypes.LOAD_DASHBOARD_TILES_VISIBILITY,
        payload: result.data,
      });
      dispatchResults({
        type: actionTypes.COMPLETE,
        payload: actionTypes.LOAD_DASHBOARD_TILES_VISIBILITY,
      });
    })
    .catch((error) => {
      console.log(error);
      alertApiError(error);
    });
  return {
    type: actionTypes.LOADING,
    payload: actionTypes.LOAD_DASHBOARD_TILES_VISIBILITY,
  };
}
