import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from "../auth/Auth";
import Welcome from "./welcome";
import Dashboard from "./dashboard";
import { BigLoadingThing } from "../shared/LoadingThing";

const auth = new Auth();

class Home extends Component {
  principalMatchesUser = () => {
    const { user } = this.props;
    const principal_id = localStorage.getItem("principal_id");

    return !principal_id || Number(principal_id) === Number(user.id);
  };

  render() {
    const isLoggedIn = auth.isAuthenticated();
    if (isLoggedIn) {
      if (this.principalMatchesUser()) {
        return <Dashboard domain={this.props.domain} />;
      } else {
        return <BigLoadingThing />;
      }
    }
    return <Welcome />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};

const connectedComponent = connect(mapStateToProps)(Home);

export default connectedComponent;
