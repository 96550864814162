import React, { useState } from "react";
import { withRouter } from "react-router-dom";

function OneItem({ tileInfo, idx, history }) {
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    navigateToLink();
  };

  const navigateToLink = () => {
    if (tileInfo.link.startsWith("http")) {
      window.confirm("You are about to be redirected outside of Pearl Hub") &&
        window.open(tileInfo.link, "_blank");
    } else {
      history.push(tileInfo.link);
    }
  };

  return (
    <div
      style={{ display: "inline-flex" }}
      className={hovered ? "hover_grow" : "hover_shrink"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      <div className="nav-list-item">
        {tileInfo.icon && (
          <img
            src={tileInfo.icon}
            alt={tileInfo.title}
            style={{ height: 50, marginBottom: 10 }}
          />
        )}
        <h3 className="title">{tileInfo.title}</h3>
        <p>{tileInfo.description}</p>
      </div>
    </div>
  );
}

function NavList({ tiles, history }) {
  return (
    <React.Fragment>
      <div className="container-fluid w-100 nav-list">
        <div className="row row-cols-1">
          {tiles.map((tile, idx) => (
            <OneItem tileInfo={tile} idx={idx} history={history} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(NavList);
