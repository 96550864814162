import React, { Component } from "react";
import FontAwesome from "react-fontawesome";

export default class InfoBox extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div
        className={`alert adjusted alert-info w-100 ${this.props.className}`}
      >
        <FontAwesome name="info-circle" className="mr-2" />
        <span>
          <span className="h4">{this.props.title}</span>
          <div style={{ padding: "10px 0" }} className="center ng-scope">
            {this.props.msg}
          </div>
        </span>
      </div>
    );
  }
}
