import { initialState } from "../initial_state";
import update from "immutability-helper";
import * as actionTypes from "../action-types";

export default (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.LOAD_DOMAIN_APPLICATIONS:
      newState = update(state, {
        applications: { $set: action.payload },
      });
      return newState;
    case actionTypes.LOAD_DOMAIN:
      newState = update(state, {
        $set: action.payload,
      });
      return newState;
    case actionTypes.LOAD_DOMAIN_AGENTS:
      newState = update(state, {
        users: { $set: action.payload },
      });
      return newState;
    default:
      return state;
  }
};
