import React, { Component } from "react";
import FontAwesome from "react-fontawesome";

export default class WidgetBox extends Component {
  constructor(props) {
    super(props);
    this.state = { deleteMode: props.deleteMode || false };
  }

  makeId = () => {
    return this.props.label.toLowerCase().replace(/ /g, "-");
  };
  render() {
    const { deleteMode } = this.state;
    const id = this.props.id || this.makeId();
    const DeleteMode = () => {
      if (deleteMode) {
        return (
          <FontAwesome
            name="check"
            style={{
              color: "green",
              flexBasis: "fit-content",
              cursor: "pointer",
            }}
            onClick={() => this.setState({ deleteMode: !deleteMode })}
          />
        );
      } else {
        return (
          <div
            id={`${id}-trash`}
            onClick={() => this.setState({ deleteMode: !deleteMode })}
          >
            <FontAwesome
              name="trash"
              style={{
                color: "red",
                flexBasis: "fit-content",
                cursor: "pointer",
              }}
            />
          </div>
        );
      }
    };
    return (
      <div className="widget-box">
        <div className="widget-header">
          <h4>{this.props.label}</h4>
          <div
            className="d-flex"
            style={{ borderLeft: "lightgray thin solid", paddingLeft: "12px" }}
          >
            {this.props.addMode && (
              <div onClick={this.props.onAdd}>
                <FontAwesome
                  name="plus"
                  id={`${id}-add`}
                  className="mr-3"
                  style={{ color: "green", flexBasis: "fit-content" }}
                />
              </div>
            )}
            {this.props.showDeleteToggle && (
              <div>
                <DeleteMode />
              </div>
            )}
          </div>
        </div>
        <div className="widget-main">
          {React.Children.map(this.props.children, (child) =>
            React.cloneElement(child, {
              deleteMode: deleteMode,
            })
          )}
        </div>
      </div>
    );
  }
}
