import React, { Component } from "react";
import {
  getCMSClient,
  getPermisoClient,
  CMS_CONTENT_URL,
} from "../../graphql/clients";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import renderHTML from "react-render-html";

const atAGlanceQuery = gql`
  query AtAGlance($path: String!) {
    node(path: $path) {
      uuid
      fields {
        ... on atAGlance {
          title
          intro
          businessVisitors
          basicRequirements
          allowableActivities
          applicationTimeline
          applicationTimelineLong
          maximumStay
          maximumStayLong
          workPermitProcess
          workAuthorization
          dependents
          registration
          country {
            fields {
              ... on country {
                code
                name
                flag {
                  binaryUuid
                  fileName
                  width
                  height
                  sha512sum
                  fileSize
                  mimeType
                  dominantColor
                }
                partner {
                  uuid
                  fields {
                    ... on partner {
                      name
                      logo {
                        binaryUuid
                        fileName
                        width
                        height
                        sha512sum
                        fileSize
                        mimeType
                        dominantColor
                      }
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const localQuery = gql`
  query LocalQuery($code: String!) {
    country_page(code: $code) {
      custom_inserts
    }
    country(code: $code) {
      collects_biometrics
      name
    }
  }
`;

export default class AtAGlance extends Component {
  constructor(props) {
    super(props);
    this.state = { code: props.match.params.code || "us" };
  }
  render() {
    return (
      <Query
        client={getCMSClient()}
        query={atAGlanceQuery}
        variables={{ path: `/atAGlances/${this.state.code}` }}
      >
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          const aag = data.node.fields;
          const aag_uuid = data.node.uuid;

          return (
            <div className="text-left">
              <div className="country_header">
                <div className="country_name ">{aag.title}</div>
                <img
                  alt=""
                  className="country_header_img"
                  src={CMS_CONTENT_URL + aag_uuid + "/binary/headerImage"}
                  style={{ height: "120px" }}
                />
              </div>
              <div className="page_content">
                <h1>Immigration At-A-Glance</h1>
                <Query
                  client={getPermisoClient()}
                  query={localQuery}
                  variables={{ code: this.state.code }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return null;
                    if (error) return `Error!: ${error}`;
                    return (
                      <div>
                        {data.country_page.custom_inserts.length > 0 && (
                          <div
                            style={{
                              border: "black thin solid",
                              padding: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#eeeeee",
                            }}
                          >
                            {(data.country_page.custom_inserts &&
                              data.country_page.custom_inserts[0]) ||
                              null}
                          </div>
                        )}
                        {data.country.collects_biometrics && (
                          <p>
                            Note: Foreign national travelers to{" "}
                            {data.country.name} should expect to have some form
                            of biometrics (fingerprints, photograph, facial scan
                            and/or iris scan) taken upon entry and/or departure.
                          </p>
                        )}
                      </div>
                    );
                  }}
                </Query>
                <div className="mt-2">
                  <p>
                    {!aag.intro && (
                      <div>
                        Visitors who wish to enter {aag.country.fields.name} may
                        require the appropriate visa for their purposes within
                        the country.
                      </div>
                    )}
                    {aag.intro && <div>{renderHTML(aag.intro)}</div>}
                    {aag.country.fields.partner && (
                      <div className="d-flex">
                        <div>
                          <img
                            alt=""
                            className="partner_logo"
                            src={
                              CMS_CONTENT_URL +
                              aag.country.fields.partner.uuid +
                              "/binary/logo"
                            }
                            style={{ height: "60px" }}
                          />
                        </div>
                        <div>
                          Immigration information provided by{" "}
                          <a href={aag.country.fields.partner.fields.url}>
                            {aag.country.fields.partner.fields.name}
                          </a>
                          {(aag.country.fields.partner.fields.name || "")
                            .toLowerCase()
                            .indexOf("pearl") < 0
                            ? " in partnership with Pearl Law Group"
                            : ""}
                          .
                        </div>
                      </div>
                    )}
                  </p>
                  <h2>Business Visitors</h2>
                  <div className="m-2">{renderHTML(aag.businessVisitors)}</div>
                  <p
                    style={{
                      color: "rgb(0, 0, 0)",
                      fontFamily: "Times New Roman. serif",
                      fontSize: "12pt",
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    &nbsp;
                  </p>
                </div>
                <div className="box_container">
                  <div className="box_left">
                    <div className="box_outline">
                      <div className="box_title">Allowable Activities</div>
                      <div className="box_icon">
                        <img
                          alt=""
                          src="https://permiso-rails.therian.co/images/country/icons/Go.png"
                        />
                      </div>
                      <div className="page_content box_content">
                        <div className="m-2">
                          {renderHTML(aag.allowableActivities)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box_right">
                    <div className="box_outline">
                      <div className="box_title">Basic Requirements</div>
                      <div className="box_icon">
                        <img
                          alt=""
                          src="https://permiso-rails.therian.co/images/country/icons/notepad.png"
                        />
                      </div>
                      <div className="page_content box_content">
                        <div className="m-2">
                          {renderHTML(aag.basicRequirements)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box_container">
                  <div className="box_left">
                    <div className="box_outline">
                      <div className="box_title">Maximum Period of Stay</div>
                      <div className="page_content box_content">
                        <div className="m-2">{renderHTML(aag.maximumStay)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="box_right">
                    <div className="box_outline">
                      <div className="box_title">Application Timeline</div>
                      <div className="page_content box_content">
                        <div className="m-2">
                          {renderHTML(aag.applicationTimeline)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ clear: "both" }}>&nbsp;</div>
                <h2> Work Authorization</h2>
                <div className="m-2">{renderHTML(aag.workAuthorization)}</div>
                <div className="box_container">
                  <div className="box_left">
                    <div className="box_heading">Work Permit Process</div>
                    <div className="page_content">
                      <div className="box_content">
                        <div className="m-2">
                          {renderHTML(aag.workPermitProcess)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box_right">
                    <div className="box_heading">Maximum Period of Stay</div>
                    <div className="page_content box_content">
                      <img
                        alt=""
                        src="https://permiso-rails.therian.co/images/country/icons/timer.png"
                        style={{ float: "right" }}
                      />
                      <div className="m-2">
                        {renderHTML(aag.maximumStayLong)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box_container">
                  <div className="box_left">
                    <div className="box_heading">Application Timeline</div>
                    <div className="page_content box_content">
                      <img
                        alt=""
                        src="https://permiso-rails.therian.co/images/country/icons/clock.png"
                        style={{ float: "right" }}
                      />
                      <div className="m-2">
                        {renderHTML(aag.applicationTimelineLong)}
                      </div>
                    </div>
                  </div>
                  <div className="box_right">
                    <div className="box_heading">
                      <strong>Dependents</strong>
                    </div>
                    <div className="page_content box_content">
                      <strong>
                        <img
                          alt=""
                          src="https://permiso-rails.therian.co/images/country/icons/fisheye.png"
                          style={{ float: "right" }}
                        />
                      </strong>
                      <div className="m-2">{renderHTML(aag.dependents)}</div>
                    </div>
                  </div>
                </div>
                {aag.registration && (
                  <div>
                    <h2>Registration</h2>
                    <div className="m-2">{renderHTML(aag.registration)}</div>
                  </div>
                )}
                {aag.departureRequirements && (
                  <div>
                    <h2>Departure Requirements</h2>
                    <div className="m-2">
                      {renderHTML(aag.departureRequirements)}
                    </div>
                  </div>
                )}
                <div className="box_right aag_date">&nbsp;</div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
