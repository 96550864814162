import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { loadUser } from "../../../redux/actions/user_actions";
import Auth from "../../auth/Auth.js";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { Link, withRouter } from "react-router-dom";
import { RotatingPlane } from "better-react-spinkit";

const auth = new Auth();

class UserNav extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  handleUserChange = (val) => {
    localStorage.setItem("principal_id", val);
    this.props.loadUser();
    this.props.history.push("/home");
  };

  showSpinner = () => {
    let spinner = [];
    for (let i = 0; i < this.props.loading.length; i++) {
      spinner.push(<RotatingPlane size={10} key={i} className="ml-2" />);
    }
    return spinner;
  };

  render() {
    const { user } = this.props;

    if (user && user.agent) {
      let user_options = user.principals.map((principal) => {
        return { value: "" + principal.id, label: principal.email };
      });
      user_options.unshift({
        value: "" + user.agent.id,
        label: user.agent.email,
      });

      return (
        <div className="d-flex">
          {user.id !== user.agent.id && (
            <div
              onClick={() => this.handleUserChange(user.agent.id)}
              className="btn btn-admin"
            >
              Return to {user.agent.name}
            </div>
          )}
          <div>
            <Dropdown
              className="nav-item dropdown"
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
              style={{ alignSelf: "left" }}
              nav={true}
            >
              <DropdownToggle caret color="#19988b">
                <FontAwesome name="user" />
                {/* <span style={{ color: 'white', paddingLeft: '10px' }}>
                  Logged in as: {user.email}
                </span> */}
              </DropdownToggle>
              <DropdownMenu>
                {!auth.isAuthenticated() && (
                  <DropdownItem className="dropdown-item">
                    <FontAwesome name="power-off" />
                    <span onClick={auth.login}>Login</span>
                  </DropdownItem>
                )}
                {auth.isAuthenticated() && (
                  <Link className="dropdown-item" to="/profile">
                    <FontAwesome name="user-circle" />
                    <span>Profile</span>
                  </Link>
                )}
                {auth.isAuthenticated() && (
                  <div className="dropdown-item" onClick={auth.logout}>
                    <FontAwesome name="power-off" />
                    <span>Logout</span>
                  </div>
                )}
                <div className="dropdown-divider" />
                <h6 className="dropdown-header">Log in as:</h6>
                <Select
                  name="principal"
                  className="text-left"
                  onChange={this.handleUserChange}
                  value={"" + user.id}
                  options={user_options}
                  simpleValue
                  placeholder="Select a user"
                />
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      );
    } else {
      return <this.showSpinner />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    loading: state.ui.loading,
  };
};

const connectedComponent = connect(mapStateToProps, {
  loadUser,
})(UserNav);

export default withRouter(connectedComponent);
