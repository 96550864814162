import React from "react";
import { Link } from "react-router-dom";

import { sections as BusinessTravelResourcesSections } from "../standalone-pages/BusinessTravelResourcesNavPage";
import { sections as USImmigrationSections } from "../standalone-pages/USImmigrationNavPage";
import getRootNavigationTiles from "./navtiles/NavigationConfig";

const knownPaths = {
  "/home": {
    label: "Home",
  },
};

// add paths from navigation config
const addNavigationTilesRecursive = (tiles, parentLink) => {
  tiles.forEach((t) => {
    knownPaths[t.link] = { label: t.title, parent: parentLink };
    if (t.children) {
      addNavigationTilesRecursive(t.children, t.link);
    }
  });
};

addNavigationTilesRecursive(getRootNavigationTiles(), "/home");

BusinessTravelResourcesSections.forEach((sec) =>
  (sec.links || []).forEach(([label, path]) => {
    knownPaths[path] = { label, parent: "/business-travel-resources" };
  })
);
USImmigrationSections.forEach((sec) => {
  (sec.links || []).forEach(([label, path]) => {
    knownPaths[path] = { label, parent: "/us-immigration" };
  });
  (sec.sections || []).forEach((sec2) =>
    (sec2.links || []).forEach(([label, path]) => {
      knownPaths[path] = { label, parent: "/us-immigration" };
    })
  );
});

export default ({ extras = {} }) => {
  const allPaths = { ...knownPaths, ...extras };

  const paths = [];
  const addPath = (path) => {
    if (path && allPaths[path] && (allPaths[path].parent || paths.length)) {
      paths.push([path, allPaths[path]]);
      addPath(allPaths[path].parent);
    } else {
      const keys = Object.keys(allPaths);
      for (const ap of keys) {
        if (ap.endsWith("*") && path.startsWith(ap.slice(0, -2))) {
          return addPath(ap);
        }
      }
    }
  };

  try {
    const current = window.location.href
      .split(window.location.origin)
      .pop()
      .split("#")[0];
    addPath(current);
  } catch (e) {
    // ignore this
  }

  if (!paths.length) {
    return null;
  }

  return (
    <div className="breadcrumbs">
      {paths.reverse().map(([path, { label }], i) => (
        <div key={path}>
          {i < paths.length - 1 ? (
            <Link to={path}>{label}</Link>
          ) : (
            <p>{label}</p>
          )}
          {i < paths.length - 1 ? <span>&gt;&gt;</span> : null}
        </div>
      ))}
    </div>
  );
};
