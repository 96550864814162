import React, { Component } from "react";
import { BigLoadingThing } from "../shared/LoadingThing";

class Callback extends Component {
  render() {
    return <BigLoadingThing />;
  }
}

export default Callback;
